import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Companies from "../../Components/Companies";
import "../../Styles/Companies.css";
import { useQuery } from "react-query";
import { getUser } from "../../Services/Https/auth";
import Face6Icon from "@mui/icons-material/Face6";
import color from "../../Constants/colors";
import React, { useEffect, useState } from "react";
import { changePassword, updateUser } from "../../Services/Https/user";
import { enqueueSnackbar } from "notistack";
import { UserRole } from "../../Models/user";
import { PieChart } from "@mui/x-charts/PieChart";

const CompaniesPage = () => {
  const { data: userData } = useQuery("user", getUser);

  const initialUserInfo = {
    name: "",
    surname: "",
    email: "",
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    companies_limit: 999 as number | null,
    evaluation_limit: 999 as number | null,
    companies_count: 0,
    evaluations_count: 0,
  };
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(initialUserInfo);

  useEffect(() => {
    if (userData) {
      setUserInfo({
        name: userData.name,
        surname: userData.surname,
        email: userData.email,
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        companies_limit:
          userData.role === UserRole.ADMIN
            ? null
            : userData.companies_limit || 999,
        evaluation_limit:
          userData.role === UserRole.ADMIN ? null : userData.daily_limit || 999,
        companies_count: userData.companiesCount || 0,
        evaluations_count: userData.evaluations || 0,
      });
    }
  }, [userData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const editUser = async () => {
    if (userInfo.name.trim() === "") {
      enqueueSnackbar("Name cannot be empty.", { variant: "warning" });
      return;
    }

    if (userInfo.surname.trim() === "") {
      enqueueSnackbar("Surname cannot be empty.", { variant: "warning" });
      return;
    }

    if (userInfo.email.trim() === "") {
      enqueueSnackbar("Email cannot be empty.", { variant: "warning" });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userInfo.email)) {
      enqueueSnackbar("Please enter a valid email address.", {
        variant: "warning",
      });
      return;
    }

    try {
      await updateUser(userInfo.name, userInfo.surname, userInfo.email);
      enqueueSnackbar("User updated successfully.", { variant: "success" });

      if (userData) {
        userData.name = userInfo.name;
        userData.surname = userInfo.surname;
        userData.email = userInfo.email;
      }
    } catch (error) {
      enqueueSnackbar("Failed to update user.", { variant: "error" });
    }
    handleClose();
  };

  const handleChangePassword = async () => {
    const { oldPassword, newPassword, confirmNewPassword } = userInfo;

    if (newPassword.length < 7) {
      enqueueSnackbar("Password must be at least 7 characters long.", {
        variant: "warning",
      });
      return;
    }

    if (newPassword !== confirmNewPassword) {
      enqueueSnackbar("Passwords do not match.", { variant: "warning" });
      return;
    }

    try {
      await changePassword(oldPassword, newPassword);
      enqueueSnackbar("User Updated Password Successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Failed to Update User Password", {
        variant: "error",
      });
    }
    handleClose();
  };

  const userRoleToString = (role: number) => {
    switch (role) {
      case UserRole.USER:
        return "User";
      case UserRole.ADMIN:
        return "Admin";
      case UserRole.EVALUATOR:
        return "Evaluator";
      default:
        return "Unknown";
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyItems="center"
      width="100%"
    >
      <Grid item>
        <Typography variant="h2" sx={{ color: color.white }}>
          Profile Information
        </Typography>
      </Grid>
      <Grid item mt={2}>
        <Face6Icon sx={{ color: color.btn_dark, fontSize: "60px" }} />
      </Grid>
      {userData ? (
        <>
          <Grid item mt={2}>
            <Typography variant="body1" sx={{ color: color.white }}>
              Name: {userData.name}
            </Typography>
          </Grid>
          <Grid item mt={2}>
            <Typography variant="body1" sx={{ color: color.white }}>
              Surname: {userData.surname}
            </Typography>
          </Grid>
          <Grid item mt={2}>
            <Typography variant="body1" sx={{ color: color.white }}>
              Email: {userData.email}
            </Typography>
          </Grid>
          <Grid item mt={2}>
            <Typography variant="body1" sx={{ color: color.white }}>
              Role: {userRoleToString(userData.role)}
            </Typography>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "35px",
              marginBottom: "35px",
              gap: "20px",
            }}
          >
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "-20px",
                  left: "50%",
                  marginTop: "15px",
                  transform: "translateX(-50%)",
                  color: color.white,
                }}
              >
                <Typography variant="caption">Evaluation Limit</Typography>
              </div>
              <PieChart
                series={[
                  {
                    data: [
                      {
                        value:
                          userInfo.evaluations_count > 0
                            ? userInfo.evaluations_count
                            : 0.00000000000001,
                        color: color.btn_dark,
                      },
                      {
                        value:
                          userInfo.evaluation_limit === null
                            ? 0
                            : userInfo.evaluation_limit -
                              userInfo.evaluations_count,
                        color: color.white,
                      },
                    ],
                    innerRadius: 80,
                    outerRadius: 99,
                    paddingAngle: 0,
                    cornerRadius: 5,
                    startAngle: -90,
                    endAngle: 90,
                    cx: 150,
                    cy: 150,
                  },
                ]}
                width={300}
                height={150}
              />
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: color.btn_dark,
                }}
              >
                <Typography variant="body1">
                  {userInfo.evaluation_limit === null
                    ? "Unlimited"
                    : `${userInfo.evaluations_count}/${userInfo.evaluation_limit}`}
                </Typography>
              </div>
            </div>

            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "-20px",
                  left: "50%",
                  marginTop: "15px",
                  transform: "translateX(-50%)",
                  color: color.white,
                }}
              >
                <Typography variant="caption">Company Limit</Typography>
              </div>
              <PieChart
                series={[
                  {
                    data: [
                      {
                        value:
                          userInfo.companies_count > 0
                            ? userInfo.companies_count
                            : 0.00000000000001,
                        color: color.btn_dark,
                      },
                      {
                        value:
                          userInfo.companies_limit === null
                            ? 0
                            : userInfo.companies_limit -
                              userInfo.companies_count,
                        color: color.white,
                      },
                    ],
                    innerRadius: 80,
                    outerRadius: 99,
                    paddingAngle: 0,
                    cornerRadius: 5,
                    startAngle: -90,
                    endAngle: 90,
                    cx: 150,
                    cy: 150,
                  },
                ]}
                width={300}
                height={150}
              />
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: color.btn_dark,
                }}
              >
                <Typography variant="body1">
                  {userInfo.companies_limit === null
                    ? "Unlimited"
                    : `${userInfo.companies_count}/${userInfo.companies_limit}`}
                </Typography>
              </div>
            </div>
          </div>
          <Grid item mt={2}>
            <Tooltip title="Edit profile">
              <Button
                variant="text"
                color="inherit"
                onClick={handleClickOpen}
                sx={{
                  color: color.white,
                  backgroundColor: color.btn_dark,
                  marginTop: "10px",
                  "&:hover": {
                    backgroundColor: color.primary_dark,
                    color: color.btn_dark,
                  },
                }}
                aria-label="edit"
              >
                Edit Profile
              </Button>
            </Tooltip>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
              maxWidth="xl"
              fullWidth
            >
              <DialogTitle id="form-dialog-title">
                <Grid
                  sx={{
                    width: "50%",
                    mx: "auto",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h3" sx={{ color: color.white }}>
                    Edit Your Profile
                  </Typography>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <Grid
                  sx={{
                    width: "50%",
                    mx: "auto",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ color: color.white, marginTop: "16px" }}
                  >
                    Personal Information
                  </Typography>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    name="name"
                    value={userInfo.name}
                    onChange={handleChange}
                    InputLabelProps={{
                      style: { color: "white" },
                    }}
                    sx={{ marginBottom: "7px", marginTop: "7px" }}
                  />
                  <TextField
                    margin="dense"
                    id="surname"
                    label="Surname"
                    type="text"
                    fullWidth
                    name="surname"
                    value={userInfo.surname}
                    onChange={handleChange}
                    InputLabelProps={{
                      style: { color: "white" },
                    }}
                    sx={{ marginBottom: "7px", marginTop: "7px" }}
                  />
                  <TextField
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    name="email"
                    value={userInfo.email}
                    onChange={handleChange}
                    InputLabelProps={{
                      style: { color: "white" },
                    }}
                    sx={{ marginBottom: "7px", marginTop: "7px" }}
                  />
                  <Button
                    onClick={editUser}
                    sx={{
                      backgroundColor: color.btn_dark,
                      color: color.white,
                      marginBottom: "10px",
                      marginTop: "10px",
                      "&:hover": {
                        backgroundColor: color.primary_dark,
                        color: color.btn_dark,
                      },
                    }}
                    variant="outlined"
                  >
                    Save changes
                  </Button>
                  <Divider style={{ marginBottom: 16 }} />
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ color: color.white, marginTop: "16px" }}
                  >
                    Change Password
                  </Typography>
                  <TextField
                    margin="dense"
                    id="old-password"
                    label="Old Password"
                    type="password"
                    fullWidth
                    name="oldPassword"
                    value={userInfo.oldPassword}
                    onChange={handleChange}
                    InputLabelProps={{
                      style: { color: "white" },
                    }}
                    sx={{ marginBottom: "7px", marginTop: "7px" }}
                  />
                  <TextField
                    margin="dense"
                    id="new-password"
                    label="New Password"
                    type="password"
                    fullWidth
                    name="newPassword"
                    value={userInfo.newPassword}
                    onChange={handleChange}
                    InputLabelProps={{
                      style: { color: "white" },
                    }}
                    sx={{ marginBottom: "7px", marginTop: "7px" }}
                  />
                  <TextField
                    margin="dense"
                    id="confirm-new-password"
                    label="Confirm New Password"
                    type="password"
                    fullWidth
                    name="confirmNewPassword"
                    value={userInfo.confirmNewPassword}
                    onChange={handleChange}
                    InputLabelProps={{
                      style: { color: "white" },
                    }}
                    sx={{ marginBottom: "7px", marginTop: "7px" }}
                  />
                  <Button
                    onClick={handleChangePassword}
                    disabled={
                      userInfo.confirmNewPassword !== userInfo.newPassword
                    }
                    sx={{
                      backgroundColor: color.btn_dark,
                      color: color.white,
                      marginBottom: "10px",
                      marginTop: "10px",
                      "&:hover": {
                        backgroundColor: color.primary_dark,
                        color: color.btn_dark,
                      },
                    }}
                    variant="outlined"
                  >
                    Change Password
                  </Button>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  sx={{
                    backgroundColor: color.btn_dark,
                    color: color.white,
                    marginBottom: "10px",
                    marginTop: "10px",
                    "&:hover": {
                      backgroundColor: color.primary_dark,
                      color: color.btn_dark,
                    },
                  }}
                  variant="outlined"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </>
      ) : (
        <CircularProgress />
      )}
      <Grid item>
        <Typography></Typography>
      </Grid>
      <Grid item mt={5} width="100%">
        <Divider
          sx={{ margin: "auto", width: "100%", borderColor: color.btn_dark }}
        />
      </Grid>
      <Grid item mt={5} width="100%">
        <Companies />
      </Grid>
    </Grid>
  );
};

export default CompaniesPage;
