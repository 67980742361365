import KnowledgeBase from "../../Components/KnowledgeBase/KnowledgeBase";

const KnowledgeBasePage = () => {
  return (
    <div>
      <KnowledgeBase />
    </div>
  );
};

export default KnowledgeBasePage;
