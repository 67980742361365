import React, { useState, useEffect } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Box,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PolicyIcon from "@mui/icons-material/Policy";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import color from "../../Constants/colors";
import DeleteDialog from "./DeleteDialog";
import {
  getCertCategories,
  editCertCategory,
  deleteCertCategory,
} from "../../Services/Https/certifications";
import { Certification } from "../../Models/certification";
import { useCreateCertification } from "../../Hooks/useCertifications";
import { handleAxiosError } from "../../Services/Https/errorHandler";
import { useKnowledgeList } from "../../Hooks/useKnowledge";
import KnowledgeBaseSection from "./KnowledgeBaseSection";

const AddNewCategory: React.FC = () => {
  const [categoryName, setCategoryName] = useState("");
  const [isDigitalPerformance, setIsDigitalPerformance] = useState(false);
  const [knowledgeBaseIds, setKnowledgeBaseIds] = useState<number[]>([]);
  const [openKnowledgeBaseSection, setOpenKnowledgeBaseSection] =
    useState(false);

  const [existingCategories, setExistingCategories] = useState<Certification[]>(
    []
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] =
    useState<Certification | null>(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [categoryToDelete, setCategoryToDelete] =
    useState<Certification | null>(null);

  const { mutate: createCategory } = useCreateCertification();
  const {
    data: knowledgeBaseList,
    isLoading: knowledgeBaseListLoading,
    isError: knowledgeBaseListError,
  } = useKnowledgeList();

  const handleOpenDeleteDialog = (category: Certification) => {
    setCategoryToDelete(category);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = () => {
    if (categoryToDelete) {
      handleDeleteCategory(categoryToDelete.id);
      setOpenDeleteDialog(false);
      setCategoryToDelete(null);
    }
  };

  useEffect(() => {
    getCertCategories().then((categories) => {
      setExistingCategories(categories);
    });
  }, []);

  const handleSubmit = async () => {
    if (categoryName.trim() === "") {
      enqueueSnackbar("Category name cannot be empty.", { variant: "error" });
      return;
    }

    const categoryExists = existingCategories.some(
      (category) => category.name === categoryName
    );

    if (categoryExists) {
      enqueueSnackbar(`Category "${categoryName}" already exists.`, {
        variant: "error",
      });
    } else {
      createCategory(
        {
          name: categoryName,
          is_edit: false,
          digital_performance: isDigitalPerformance,
          knowledge_base_ids: knowledgeBaseIds,
        },
        {
          onSuccess: () => {
            enqueueSnackbar(
              `Category "${categoryName}" created successfully.`,
              { variant: "success" }
            );
            navigate(`/cert_category/`);
            getCertCategories().then((categories) => {
              setExistingCategories(categories);
            });
          },
          onError: (error) => {
            const errorMessage = handleAxiosError(error);
            enqueueSnackbar(`Error creating category: ${errorMessage}`, {
              variant: "error",
            });
          },
        }
      );
    }
  };

  const handleEditCategory = (category: Certification) => {
    setSelectedCategory(category);
    setCategoryName(category.name);
    setIsDigitalPerformance(category.digital_performance);
    setOpenDialog(true);
  };

  const handleSaveCategory = async () => {
    if (selectedCategory) {
      try {
        await editCertCategory(selectedCategory.id, {
          name: categoryName,
          digital_performance: isDigitalPerformance,
          is_edit: false,
          knowledge_base_ids: knowledgeBaseIds,
        });
        enqueueSnackbar(`Category "${categoryName}" successfully updated.`, {
          variant: "success",
        });
        setOpenDialog(false);
        getCertCategories().then((categories) => {
          setExistingCategories(categories);
        });
      } catch (error) {
        enqueueSnackbar("Failed to update category. Please try again.", {
          variant: "error",
        });
      }
    }
  };

  const handleDeleteCategory = async (categoryId: number) => {
    try {
      await deleteCertCategory(categoryId);
      enqueueSnackbar("Category successfully deleted.", {
        variant: "success",
      });
      getCertCategories().then((categories) => {
        setExistingCategories(categories);
      });
    } catch (error) {
      const errorMessage = handleAxiosError(error);
      enqueueSnackbar(`Failed to delete category. Error: ${errorMessage}`, {
        variant: "error",
      });
    }
  };

  const handleKnowledgeBaseToggle = (id: number) => {
    setKnowledgeBaseIds((prev) =>
      prev.includes(id) ? prev.filter((kbId) => kbId !== id) : [...prev, id]
    );
  };

  const toggleKnowledgeBaseSection = () => {
    setOpenKnowledgeBaseSection((prev) => !prev);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding="3rem"
      >
        <Typography variant="h4" color={color.white}>
          Add a New Certification Category
        </Typography>
        <TextField
          label="Category Name"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          variant="outlined"
          margin="normal"
          sx={{ width: "40%" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isDigitalPerformance}
              onChange={(e) => setIsDigitalPerformance(e.target.checked)}
              color="primary"
            />
          }
          label="Digital Performance"
          style={{ color: color.white }}
        />
        <KnowledgeBaseSection
          knowledgeBaseIds={knowledgeBaseIds}
          knowledgeBaseList={knowledgeBaseList}
          knowledgeBaseListLoading={knowledgeBaseListLoading}
          knowledgeBaseListError={knowledgeBaseListError}
          toggleKnowledgeBaseSection={toggleKnowledgeBaseSection}
          handleKnowledgeBaseToggle={handleKnowledgeBaseToggle}
          openKnowledgeBaseSection={openKnowledgeBaseSection}
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: color.btn_dark,
            color: "white",
            marginTop: "20px",
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>

        <Divider
          flexItem
          variant="inset"
          sx={{
            backgroundColor: color.white,
            marginTop: "2rem",
          }}
        />

        <Grid item width={"50%"} margin={"2rem auto"}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                    }}
                  >
                    <TableSortLabel>Category Name</TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    Digital Performance
                  </TableCell>
                  <TableCell
                    style={{ color: color.white, textAlign: "center" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {existingCategories.map((category) => (
                  <TableRow
                    key={category.id}
                    sx={{
                      "&:hover": { backgroundColor: color.primary_dark },
                    }}
                  >
                    <TableCell>{category.name}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {category.digital_performance && (
                        <PolicyIcon style={{ color: color.btn_dark }} />
                      )}
                    </TableCell>
                    <TableCell
                      style={{ color: color.white, textAlign: "center" }}
                    >
                      <Tooltip title={"Edit"}>
                        <span>
                          <IconButton
                            style={{ color: color.btn_dark }}
                            onClick={() => handleEditCategory(category)}
                          >
                            <EditIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title={"Delete"}>
                        <span>
                          <IconButton
                            style={{ color: color.red }}
                            onClick={() => handleOpenDeleteDialog(category)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Certification Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Category Name"
            type="text"
            fullWidth
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isDigitalPerformance}
                onChange={(e) => setIsDigitalPerformance(e.target.checked)}
                color="primary"
              />
            }
            label="Digital Performance"
            style={{ color: color.black }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            sx={{
              backgroundColor: color.btn_dark,
              color: color.white,
              marginBottom: "10px",
              marginTop: "10px",
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveCategory}
            sx={{
              backgroundColor: color.btn_dark,
              color: color.white,
              marginBottom: "10px",
              marginTop: "10px",
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={handleConfirmDelete}
        categoryName={categoryToDelete ? categoryToDelete.name : ""}
      />
    </>
  );
};

export default AddNewCategory;
