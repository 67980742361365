import { Button, Grid, Tooltip } from "@mui/material";
import color from "../../../Constants/colors";

interface FileUploaderActionProps {
  buttonTitle: string;
  tooltipTitle: string;
  disableHoverListener: boolean;
  disabled: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const FileUploaderAction: React.FC<FileUploaderActionProps> = ({
  buttonTitle,
  tooltipTitle,
  disableHoverListener,
  disabled,
  onClick,
}) => {
  return (
    <Grid item>
      <Tooltip title={tooltipTitle} disableHoverListener={disableHoverListener}>
        <span>
          <Button
            sx={{
              backgroundColor: color.btn_dark,
              color: color.white,
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
            }}
            disabled={disabled}
            variant="outlined"
            onClick={onClick}
          >
            {buttonTitle}
          </Button>
        </span>
      </Tooltip>
    </Grid>
  );
};

export default FileUploaderAction;
