import { useMutation } from "react-query";
import { uploadCSVFile } from "../Services/Https/dussman";
import { useSnackbar } from "notistack";

export const useUploadCSVFile = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(uploadCSVFile, {
    onSuccess: () => {
      setTimeout(() => {
        enqueueSnackbar(
          "File submitted to processing, your knowledge base will be updated shortly!",
          {
            variant: "success",
          }
        );
      });
    },
    onError: () => {
      setTimeout(() => {
        enqueueSnackbar(
          "Oops, something went wrong. Failed to update the knowledge base. Please try again.",
          {
            variant: "error",
          }
        );
      });
    },
  });
};
