import { useState } from "react";

export function useSortableData<T>(
  items: T[],
  initialSortField: keyof T,
  initialSortOrder: "asc" | "desc" = "asc"
) {
  const [sortField, setSortField] = useState<keyof T>(initialSortField);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">(initialSortOrder);

  const handleSort = (field: keyof T) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortField(field);
  };

  const sortedItems = items.sort((a, b) => {
    const aValue = a[sortField];
    const bValue = b[sortField];

    if (typeof aValue === "object" && aValue !== null && bValue !== null) {
      const aNestedValue = (aValue as any).name;
      const bNestedValue = (bValue as any).name;
      return (
        (aNestedValue < bNestedValue ? -1 : 1) * (sortOrder === "asc" ? 1 : -1)
      );
    }

    if (aValue < bValue) {
      return sortOrder === "asc" ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortOrder === "asc" ? 1 : -1;
    }
    return 0;
  });

  return { sortedItems, sortField, sortOrder, handleSort };
}
