import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import "../../Styles/HomePage.css";
import { fetchEvaluate } from "../../Services/Https/llm";
import { ModelLevel, User, UserRole } from "../../Models/user";
import color from "../../Constants/colors";
import {
  useCheckProgress,
  useCompanies,
  useCompanyStatus,
} from "../../Hooks/useCompanies";
import { CompanyStatus } from "../../Constants/Enums/companyStatus";
import { CompanyInfo } from "../../Models/company";
import { getSingleCompany } from "../../Services/Https/company";
import CategoryAndQuestionForm from "./CategoryAndQustionForm";
import EvaluationLegent from "./EvaluationLegent";
import Loader from "../UI/Loader";
import CompanyStatusContent from "./CompanyStatusContent ";
import EvaluateButton from "./EvaluateButton";
import EvaluationInProgress from "./EvaluationInProgress";
import SelectCompany from "./SelectCompany";
import LanguageSelect from "./LanguageSelect";
import theme from "../../Constants/theme";
import { handleAxiosError } from "../../Services/Https/errorHandler";
import { useEvaluationModel } from "../../Hooks/useEvaluation";
import { evaluationModel } from "../../Models/evaluationModel";

interface HomeComponentProps {
  currentUserRole: UserRole | undefined;
  user: User | undefined;
}

const HomeComponent: React.FC<HomeComponentProps> = ({
  currentUserRole,
  user,
}) => {
  const [isError, setIsError] = useState(false);
  const [isTimingError, setIsTimingError] = useState(false);

  const [company, setCompany] = useState<CompanyInfo>();
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | "">("");
  const [selectedModelId, setSelectedModelId] = useState<number | "">("");
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isEvaluateLoading, setIsEvaluateLoading] = useState(false);
  const [languageId, setLanguageId] = useState<number>(0);
  const [chunkSize] = useState(3000);
  const [chunkOverlap] = useState(700);
  const [certificationId, setCertificationId] = useState("");
  const [questionListId, setQuestionListId] = useState("");
  const [modelOptions, setModelOptions] = useState<evaluationModel[]>([]);
  const [companiesIntervalId, setCompaniesIntervalId] =
    useState<NodeJS.Timeout>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isEvaluationFinished, setIsEvaluationFinished] = useState(false);
  const [isRefetchihngCompany, setIsRefetchingCompany] = useState(false);
  const {
    data: companyResponse,
    refetch: refetchCompanies,
    isLoading: areCompaniesLoading,
  } = useCompanies(1, 20, "name", "asc", "", false);

  const { data: useEvaluationModelData, isLoading: useEvaluationModelLoading } =
    useEvaluationModel();

  const [companyId, setCompanyId] = useState<number | null>();

  const { data: checkProgressData, refetch: refetchCheckProgressData } =
    useCheckProgress();

  useEffect(() => {
    if (checkProgressData?.in_progress === true) {
      if (checkProgressData?.company) {
        setCompanyId(checkProgressData?.company.id);
      }
    }
  }, [setCompanyId, checkProgressData]);

  const { data: companyStatus } = useCompanyStatus(companyId?.toString());

  useEffect(() => {
    if (useEvaluationModelData && user) {
      const userLevel = user.model_level as ModelLevel;
      const userModelAccess = useEvaluationModelData.model_access.find(
        (level) => level.level_id === userLevel
      );

      setModelOptions(userModelAccess ? userModelAccess.models : []);
    }
  }, [useEvaluationModelData, user]);

  useEffect(() => {
    return () => {
      clearInterval(companiesIntervalId);
    };
  }, [companiesIntervalId]);

  const handleSelectChange = (event: SelectChangeEvent<number>) => {
    setSelectedCompanyId(Number(event.target.value));
  };

  const handleModelChange = (event: SelectChangeEvent<number>) => {
    setSelectedModelId(Number(event.target.value));
  };

  const handleLanguageChange = (id: number) => {
    setLanguageId(id);
  };

  const navigateToCreateCompany = () => {
    navigate("/company/create");
  };

  const fetchSingleCompany = useCallback(
    async (id: string) => {
      try {
        let data = await getSingleCompany(id);
        setCompany(data);
      } catch (error) {
        const errorMessage = handleAxiosError(error);
        enqueueSnackbar(`Error fetching company: ${errorMessage}`, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );

  const handleEvaluateClick = async (
    questionListId: number,
    certificationId: number
  ): Promise<void> => {
    setIsError(false);
    setCompany(undefined);
    setIsButtonClicked(true);
    setIsEvaluateLoading(true);

    if (selectedModelId !== "" && selectedCompanyId) {
      try {
        await fetchEvaluate(
          selectedCompanyId,
          selectedModelId,
          chunkSize,
          chunkOverlap,
          questionListId,
          certificationId,
          languageId
        );
        setCompanyId(selectedCompanyId);
        setLanguageId(languageId);
        setIsRefetchingCompany(true);
        refetchCheckProgressData();
      } catch (error: any) {
        setIsEvaluateLoading(false);

        enqueueSnackbar(error.message, { variant: "error" });
      }
    } else {
      setIsEvaluateLoading(false);
      enqueueSnackbar(
        "Please select both a company and a model before evaluating.",
        { variant: "warning" }
      );
    }
  };

  useEffect(() => {
    if (
      company &&
      company.status !== CompanyStatus.EVALUATION_IN_PROGRESS &&
      company.status !== CompanyStatus.GATHERING_DIGITAL_PERFORMANCE
    ) {
      setCompany(company);
      setIsEvaluateLoading(false);
      setCompanyId(null);
    }
    if (company && company.status === CompanyStatus.ERROR) {
      setIsError(true);
    }
    if (company && company.status === CompanyStatus.TIME_LIMIT_EXCEEDED) {
      setIsTimingError(true);
    }
  }, [company]);

  useEffect(() => {
    if (company) {
      if (
        company.status === CompanyStatus.EVALUATED ||
        company.status === CompanyStatus.NEEDS_MORE_INFO
      ) {
        setIsEvaluationFinished(true);
        setCompanyId(null);
      } else {
        setIsEvaluationFinished(false);
      }
    }
  }, [company]);

  useEffect(() => {
    if (companyStatus) {
      if (
        Number(companyStatus.status) === CompanyStatus.EVALUATED ||
        Number(companyStatus.status) === CompanyStatus.NEEDS_MORE_INFO
      ) {
        if (selectedCompanyId && isRefetchihngCompany === true) {
          fetchSingleCompany(selectedCompanyId.toString());
        } else {
          setCompanyId(null);
        }
        setIsEvaluateLoading(false);
      }
      if (
        Number(companyStatus.status) === CompanyStatus.EVALUATION_IN_PROGRESS ||
        Number(companyStatus.status) ===
          CompanyStatus.GATHERING_DIGITAL_PERFORMANCE
      )
        setIsEvaluateLoading(true);
    }
  }, [
    companyStatus,
    fetchSingleCompany,
    selectedCompanyId,
    checkProgressData,
    isRefetchihngCompany,
  ]);

  if (
    checkProgressData?.in_progress === true &&
    companyStatus &&
    Number(companyStatus.status) !== CompanyStatus.EVALUATED &&
    Number(companyStatus.status) !== CompanyStatus.NEEDS_MORE_INFO
  ) {
    const model_id =
      checkProgressData?.company.last_evaluation_request.model_id;
    const model = modelOptions.find((m) => m.model_id === model_id);

    if (model) {
      const modelName = model.model_name;
      return (
        <Grid>
          <EvaluationInProgress
            modelName={modelName}
            certificationCategoryName={
              checkProgressData?.company.last_evaluation_request.question_list
                .category
            }
            questionListName={
              checkProgressData?.company.last_evaluation_request.question_list
                .name
            }
            companyName={checkProgressData?.company.name}
            companyStatus={checkProgressData?.company.status}
          ></EvaluationInProgress>
        </Grid>
      );
    }
  }
  if (companiesIntervalId) {
    clearInterval(companiesIntervalId);
    setCompaniesIntervalId(undefined);
  }
  if (!user) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid
      container
      sx={{
        width: "100vw",
        margin: "0 auto",
        pt: "2vh",
      }}
      alignContent="center"
      justifyContent="center"
      direction="column"
      alignItems="center"
    >
      {isError && (
        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          direction="column"
          mb={5}
        >
          <Typography variant="h3" sx={{ color: color.red }}>
            An error occurred while evaluating the company. Please try again.
          </Typography>
        </Grid>
      )}
      {isTimingError && (
        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          direction="column"
          mb={5}
        >
          <Typography variant="h3" sx={{ color: color.red }}>
            Time limit exceeded
          </Typography>
        </Grid>
      )}
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography variant="h2" sx={{ color: color.white }}>
          Evaluate Your Company
        </Typography>
      </Grid>
      <Grid item width="50%" sx={{ mt: { xs: 3, sm: 4 } }}>
        <Divider
          sx={{
            margin: "auto",
            width: "100%",
            borderColor: color.accent,
          }}
        />
      </Grid>
      <Grid
        item
        mt={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
        }}
      ></Grid>

      <EvaluationLegent
        style={{ opacity: isEvaluationFinished ? "0.3" : "1" }}
      />

      <SelectCompany
        selectedCompanyId={selectedCompanyId}
        handleSelectChange={handleSelectChange}
        companyResponse={companyResponse}
        isEvaluateLoading={isEvaluateLoading}
        navigateToCreateCompany={navigateToCreateCompany}
        onRefetchCompanies={refetchCompanies}
        areCompaniesLoading={areCompaniesLoading}
      />

      <Grid item sx={{ width: { xs: "90%", sm: "70%", md: "30%" }, mt: 5 }}>
        <FormControl fullWidth>
          <InputLabel
            sx={{
              color: theme.palette.primary.main,
              fontFamily: theme.typography.body1.fontFamily,
              fontWeight: theme.typography.body1.fontWeight,
              fontSize: theme.typography.body1.fontSize,
              letterSpacing: theme.typography.body1.letterSpacing,
              lineHeight: theme.typography.body1.lineHeight,
              "&.Mui-focused": {
                color: theme.palette.primary.main,
              },
            }}
            id="model-select-label"
          >
            Select Evaluation Model
          </InputLabel>
          <Select
            labelId="model-select-label"
            id="model-select"
            value={selectedModelId}
            label="Select Evaluation Model"
            onChange={handleModelChange}
            disabled={isEvaluateLoading || useEvaluationModelLoading}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: "#032e4d",
                  opacity: 0.95,
                },
              },
            }}
          >
            {modelOptions.map((model) => (
              <MenuItem key={model.model_id} value={model.model_id}>
                <Typography
                  variant="body1"
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  {model.model_name}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sx={{ width: { xs: "90%", sm: "70%", md: "30%" }, mt: 5 }}>
        <CategoryAndQuestionForm
          certificationId={certificationId}
          setCertificationId={setCertificationId}
          questionListId={questionListId}
          setQuestionListId={setQuestionListId}
          currentUserRole={currentUserRole}
          userId={user?.id}
          isEvaluateLoading={isEvaluateLoading}
          selectedModelId={selectedModelId}
        />
      </Grid>
      <Grid item width="50%" sx={{ mt: 6 }}>
        <Divider
          sx={{ margin: "auto", width: "100%", borderColor: color.accent }}
        />
        <LanguageSelect
          languageId={languageId}
          onLanguageChange={handleLanguageChange}
        />
      </Grid>
      <Grid item>
        {!isEvaluateLoading &&
          (!company ||
            company.status === CompanyStatus.ERROR ||
            company.status === CompanyStatus.TIME_LIMIT_EXCEEDED) && (
            <EvaluateButton
              handleEvaluateClick={handleEvaluateClick}
              selectedCompanyId={selectedCompanyId}
              selectedModelId={selectedModelId}
              certificationId={certificationId}
              questionListId={questionListId}
              currentUserRole={currentUserRole}
              isButtonClicked={isButtonClicked}
            />
          )}
        {isEvaluateLoading &&
          company?.status !== CompanyStatus.NEEDS_MORE_INFO &&
          company?.status !== CompanyStatus.WAITING_FOR_EVALUATION &&
          company?.status !== CompanyStatus.EVALUATED &&
          company?.status !== CompanyStatus.ERROR &&
          company?.status !== CompanyStatus.TIME_LIMIT_EXCEEDED &&
          company?.status !== CompanyStatus.GATHERING_DIGITAL_PERFORMANCE && (
            <Loader
              src="orange_loader.gif"
              message="Evaluation in progress..."
            />
          )}

        {isEvaluateLoading &&
          company?.status === CompanyStatus.GATHERING_DIGITAL_PERFORMANCE && (
            <Loader
              src="magenta_loader.gif"
              message="Gathering digital performance..."
            />
          )}

        {!isEvaluateLoading && (
          <CompanyStatusContent
            company={company}
            selectedCompanyId={selectedCompanyId}
            questionListId={questionListId}
            certificationId={certificationId}
            languageId={languageId}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default HomeComponent;
