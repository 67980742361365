import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import colors from "../../Constants/colors";
import {
  useKnowledge,
  useSingleKnowledge,
  useUpdateKnowledge,
} from "../../Hooks/useKnowledge";
import FileUploader from "../UI/FileUploader/FileUploader";
import FileUploaderAction from "../UI/FileUploader/FileUploaderAction";
import { enqueueSnackbar } from "notistack";
import { allowedExtensionsKnowledge } from "../../helpers/helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAxiosError } from "../../Services/Https/errorHandler";

const KnowledgeBase = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [mode, setMode] = useState<"create" | "edit">("create");

  const navigate = useNavigate();
  const { knowledgeBaseId } = useParams<{ knowledgeBaseId?: string }>();
  const location = useLocation();

  const { mutate: createMutate, isLoading: isCreating } = useKnowledge();
  const {
    data: singleData,
    isLoading: isFetching,
    isError: isFetchingError,
    error: fetchingError,
  } = useSingleKnowledge(Number(knowledgeBaseId));
  const { mutate: updateMutate, isLoading: isUpdating } = useUpdateKnowledge();

  if (isFetchingError && fetchingError.response?.status === 404) {
    enqueueSnackbar("The requested knowledge base no longer exists.", {
      variant: "error",
    });
    navigate("/knowledge_base/");
  }

  useEffect(() => {
    if (knowledgeBaseId) {
      setMode("edit");
      setName("");
      setDescription("");
      setFiles([]);
    } else {
      setMode("create");
      setName("");
      setDescription("");
      setFiles([]);
    }
  }, [knowledgeBaseId]);

  useEffect(() => {
    if (mode === "edit" && singleData) {
      setName(singleData.name);
      setDescription(singleData.description);
    }
  }, [mode, singleData]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (mode === "create") {
      createMutate(
        { name, description, files },
        {
          onSuccess: () => {
            enqueueSnackbar("Successfully created a Knowledge Base", {
              variant: "success",
            });
            navigate("/knowledge_base/");
          },
          onError: (error) => {
            const errorMessage = handleAxiosError(error);
            enqueueSnackbar(
              `Error while creating the knowledge base: ${errorMessage}`,
              {
                variant: "error",
              }
            );
          },
        }
      );
    } else {
      updateMutate(
        { knowledgeBaseId: Number(knowledgeBaseId), name, description },
        {
          onSuccess: () => {
            enqueueSnackbar("Successfully updated the Knowledge Base", {
              variant: "success",
            });
            navigate("/knowledge_base/");
          },
          onError: (error) => {
            const errorMessage = handleAxiosError(error);
            enqueueSnackbar(
              `Error while updating the knowledge base: ${errorMessage}`,
              {
                variant: "error",
              }
            );
          },
        }
      );
    }
  };

  const handleRemoveFile = (fileName: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleOpenFile = (file: File) => {
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const clearAllFiles = () => {
    setFiles([]);
    setPage(0);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ padding: 4, backgroundColor: "transparent", borderRadius: 2 }}>
        <Typography
          variant="h4"
          gutterBottom
          textAlign="center"
          sx={{ color: colors.white }}
        >
          {mode === "create" ? "Create Knowledge Base" : "Edit Knowledge Base"}
        </Typography>

        {isFetching && !singleData ? (
          <CircularProgress />
        ) : (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 4 }}
            key={location.pathname}
          >
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  disabled={isCreating || isUpdating}
                  InputLabelProps={{ style: { color: colors.white } }}
                  InputProps={{
                    sx: {
                      color: colors.white,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} mt={2}>
                <TextField
                  fullWidth
                  label="Description"
                  variant="outlined"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiline
                  disabled={isCreating || isUpdating}
                  rows={4}
                  InputLabelProps={{ style: { color: colors.white } }}
                  InputProps={{
                    sx: {
                      color: colors.white,
                    },
                  }}
                />
              </Grid>

              {mode === "create" && (
                <>
                  <Grid item xs={12} mt={2}>
                    <FileUploader
                      uploadedFiles={files}
                      setUploadedFiles={setFiles}
                      allowedExtensions={allowedExtensionsKnowledge}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      handleRemoveFile={handleRemoveFile}
                      handleOpenFile={handleOpenFile}
                      disabled={isCreating || isUpdating}
                    />
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FileUploaderAction
                      buttonTitle="Clear"
                      tooltipTitle="No files to clear"
                      disableHoverListener={files.length > 0}
                      disabled={isCreating || isUpdating || files.length === 0}
                      onClick={clearAllFiles}
                    />
                    <TablePagination
                      component="div"
                      count={files.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      disabled={isCreating || isUpdating}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={({ from, to, count }) =>
                        `Showing ${from}-${to} of ${count} files`
                      }
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Box mt={2} textAlign="center">
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: colors.btn_dark,
                      color: colors.white,
                      "&:hover": {
                        backgroundColor: colors.primary_dark,
                      },
                      padding: 2,
                      marginTop: "50px",
                    }}
                    disabled={isCreating || isUpdating}
                  >
                    <Typography variant="body1">
                      {isCreating || isUpdating ? (
                        <CircularProgress />
                      ) : mode === "create" ? (
                        "Submit"
                      ) : (
                        "Update"
                      )}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default KnowledgeBase;
