import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { styled } from "@mui/system";
import color from "../../Constants/colors";

const Section = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  background: `linear-gradient(to bottom, rgba(3, 46, 77, 0.85), rgba(6, 70, 116, 0.85)), url(/background.png)`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  color: color.white,
  justifyContent: "center",
  alignItems: "flex-start",
  textAlign: "left",
  paddingLeft: "5%",
  position: "relative",
  animation: "fadeIn 2s ease-in",
  "@keyframes fadeIn": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  backgroundAttachment: "fixed",
  width: "100vw",
}));

const TextBox = styled(Box)(({ theme }) => ({
  zIndex: 2,
  maxWidth: "90%",
  marginTop: "-10rem",
  animation: "fadeText 2s ease-in",
  "@keyframes fadeText": {
    from: { opacity: 0, transform: "translateY(20px)" },
    to: { opacity: 1, transform: "translateY(0)" },
  },
}));

const DecorativeLine = styled(Box)(({ theme }) => ({
  height: "4px",
  width: "10vw",
  background: color.accent,
  marginBottom: "2rem",
  animation: "lineSlideIn 2s ease-out",
  "@keyframes lineSlideIn": {
    from: { width: 0 },
    to: { width: "10vw" },
  },
}));

const HeroSection: React.FC = () => {
  return (
    <Section>
      <TextBox>
        <DecorativeLine />
        <Typography variant="h2" gutterBottom>
          Connected Innovation Hub
        </Typography>
        <Typography variant="h4" gutterBottom sx={{ color: color.btn_dark }}>
          From Evaluation to Execution, Your Partner in Digital Success
        </Typography>
        <Typography
          variant="body1"
          sx={{
            width: { xs: "100%", sm: "80%", md: "70%", lg: "80%", xl: "70%" },
          }}
        >
          Discover the future of business evaluation with our state-of-the-art
          AI models, designed to assess your company against the most current
          industry standards. Our platform, guided by experienced consultants,
          delivers comprehensive and time-consuming evaluations in just minutes.
          Join us and transform your business with precision, speed, and
          expertise like never before!
        </Typography>
        <Link
          href="https://corporatemomentum.zohobookings.eu/#/109854000000018042"
          target="_blank"
          rel="noopener noreferrer"
          underline="none"
          sx={{
            display: "inline-block",
            marginTop: 5,
            padding: "10px 20px",
            borderRadius: "30px",
            backgroundColor: color.btn_dark,
            color: color.white,
            textAlign: "center",
            textDecoration: "none",
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
        >
          Get Access
        </Link>
      </TextBox>
    </Section>
  );
};

export default HeroSection;
