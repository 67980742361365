import { CircularProgress, Typography } from "@mui/material";

interface LoaderProps {
  src?: string;
  message?: string;
}

const Loader: React.FC<LoaderProps> = ({ src, message }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100px",
    }}
  >
    {src && (
      <img
        src={src}
        alt="Loading"
        className="fade-in"
        style={{
          width: "200px",
          position: "relative",
          borderRadius: "50%",
        }}
      />
    )}
    {!src && <CircularProgress />}
    {message && (
      <Typography
        variant="body1"
        sx={{ textAlign: "center", color: "white", marginLeft: "10px" }}
      >
        {message}
      </Typography>
    )}
  </div>
);

export default Loader;
