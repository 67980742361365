import React, { CSSProperties, useState } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import color from '../../Constants/colors';

const ReleaseNotes: React.FC = () => {
    const [expanded, setExpanded] = useState(false);

    const releaseNotes = [
        {
            date: 'October 22, 2024',
            notes: [
                {
                    title: 'Initial release',
                    description: 'The application is now live with its initial features.',
                    fullDescription: 'The application is live with features like user authentication, dashboards, and more. Explore all sections to get a complete overview of what’s available. This launch marks the beginning of a new journey for users seeking efficiency and streamlined workflows.',
                    category: 'General',
                },
                {
                    title: 'Fixed minor bugs',
                    description: 'Several minor bugs have been fixed to improve stability.',
                    fullDescription: 'These fixes include resolving UI inconsistencies and minor backend issues related to API responses, improving overall stability. This ensures a smoother experience as users navigate the application and enhances reliability across various functionalities.',
                    category: 'Maintenance',
                },
                {
                    title: 'Added release notes feature',
                    description: 'A new feature for displaying release notes has been added.',
                    fullDescription: 'Users can now view detailed release notes on new features, bug fixes, and improvements directly within the application. This feature helps keep users informed about ongoing updates and encourages engagement with the platform.',
                    category: 'Frontend',
                },
                {
                    title: 'Improved user interface',
                    description: 'The user interface has been updated for better usability.',
                    fullDescription: 'UI improvements include more intuitive navigation, cleaner design elements, and optimized performance across all pages. These enhancements contribute to a more pleasant user experience, making tasks easier and quicker to accomplish.',
                    category: 'UI/UX',
                },
            ],
        },
    ];

    const containerStyle: CSSProperties = {
        backgroundColor: color.primary_medium,
        padding: '20px',
        borderRadius: '12px',
        color: color.white,
        display: 'flex',
        flexDirection: 'column',
        width: '350px',
        height: '100vh',
        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
        justifyContent: 'flex-start',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
    };

    const scrollContainerStyle: CSSProperties = {
        ...containerStyle,
        overflowY: 'scroll',
    };

    const titleStyle: CSSProperties = {
        fontSize: '24px',
        marginBottom: '4px',
        fontWeight: 'bold',
        color: color.accent,
        textShadow: '1px 1px 4px rgba(0, 0, 0, 0.3)',
    };

    const dateStyle: CSSProperties = {
        fontSize: '16px',
        marginBottom: '12px',
        marginTop: '0',
        fontWeight: 'bold',
        color: color.accent,
        textShadow: '1px 1px 4px rgba(0, 0, 0, 0.3)',
    };

    const listStyle: CSSProperties = {
        listStyleType: 'none',
        padding: 0,
        marginBottom: '20px',
    };

    const itemStyle: CSSProperties = {
        margin: '10px 0',
        padding: '12px',
        backgroundColor: color.secondary,
        borderRadius: '8px',
        transition: 'background-color 0.3s, transform 0.2s',
        color: color.white,
        borderLeft: `5px solid ${color.accent}`,
        fontSize: '16px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    };

    const buttonStyle: CSSProperties = {
        marginTop: 'auto',
        padding: '12px 0',
        color: color.primary_dark,
        fontSize: '16px',
        cursor: 'pointer',
        textDecoration: 'none',
        background: 'none',
        border: 'none',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        borderTop: `1px solid ${color.accent}`,
    };

    const iconStyle: CSSProperties = {
        marginRight: '8px',
        color: color.primary_dark,
    };

    const handleButtonClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div style={scrollContainerStyle}>
            <h2 style={titleStyle}>What's New?</h2>
            {releaseNotes.map((note, index) => (
                <div key={index}>
                    <h4 style={dateStyle}>{note.date}</h4>
                    <ul style={listStyle}>
                        {note.notes.map((item, noteIndex) => (
                            <li
                                key={noteIndex}
                                style={itemStyle}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor = color.primary_dark;
                                    e.currentTarget.style.transform = 'scale(1.02)';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = color.secondary;
                                    e.currentTarget.style.transform = 'scale(1)';
                                }}
                            >
                                <strong>{item.title}</strong>: {item.description} <em>({item.category})</em>
                                {expanded && (
                                    <div>
                                        <p>{item.fullDescription}</p>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
            <button style={buttonStyle} onClick={handleButtonClick}>
                <DescriptionIcon style={iconStyle} />
                {expanded ? 'Hide full article' : 'Show full article in the documentation'}
            </button>
        </div>
    );
};

export default ReleaseNotes;
