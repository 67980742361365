import KnowledgeBaseAll from "../../Components/KnowledgeBase/KnowledgeBaseAll";

const KnowledgeBaseAllPage = () => {
  return (
    <div>
      <KnowledgeBaseAll />
    </div>
  );
};

export default KnowledgeBaseAllPage;
