import { useQuery } from "react-query";
import { evaluationModelAccessResponse } from "../Models/evaluationModel";
import { fetchModelOptions } from "../Services/Https/llm";
import { handleAxiosError } from "../Services/Https/errorHandler";
import { enqueueSnackbar } from "notistack";

export const useEvaluationModel = () => {
  return useQuery<evaluationModelAccessResponse, Error>(
    ["evaluationModel"],
    () => fetchModelOptions(),
    {
      onError: (error) => {
        const errorMessage = handleAxiosError(error);
        enqueueSnackbar(`Error fetching evaluation models: ${errorMessage}`, {
          variant: "error",
        });
      },
    }
  );
};
