import axios from "axios";
import {
  CreateKnowledgeBaseResponse,
  GetAllKnowledgeBasesResponse,
  GetSingleKnowledgeBaseResponse,
} from "../../Models/knowledge";

export const createKnowledgeBase = async (
  name: string,
  description: string,
  files: File[]
): Promise<CreateKnowledgeBaseResponse> => {
  const formData = new FormData();

  formData.append("name", name);
  formData.append("description", description);
  files.forEach((file) => {
    formData.append("files", file);
  });

  const response = await axios.post<CreateKnowledgeBaseResponse>(
    `${process.env.REACT_APP_BASE_URL}/v1/knowledge_base/`,
    formData,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response.data;
};

export const getKnowledgeBase = async () => {
  const response = await axios.get<GetAllKnowledgeBasesResponse[]>(
    `${process.env.REACT_APP_BASE_URL}/v1/knowledge_base/`,
    { withCredentials: true }
  );
  return response.data;
};

export const getSingleKnowledgeBase = async (
  id: number
): Promise<GetSingleKnowledgeBaseResponse> => {
  const { data } = await axios.get<GetSingleKnowledgeBaseResponse>(
    `${process.env.REACT_APP_BASE_URL}/v1/knowledge_base/${id}/`,
    { withCredentials: true }
  );
  return data;
};

export const updateSingleKnowledgeBase = async (
  id: number | null,
  name: string = "",
  description: string = ""
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BASE_URL}/v1/knowledge_base/${id}/`,
    {
      name: name,
      description: description,
    },
    {
      withCredentials: true,
    }
  );
  return response;
};

export const deleteKnowledgeBase = async (id: number): Promise<void> => {
  await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/v1/knowledge_base/${id}/`,
    {
      withCredentials: true,
    }
  );
};
