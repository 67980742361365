import { jsPDF } from "jspdf";
import { CategoryDetail, EvaluationResponse } from "../Models/evaluation";
import { getTitleFromCamelcase } from "./helpers";
import { Document, Packer, Paragraph, TextRun } from "docx";

function splitTextIntoLinesForPdf(
  text: string = "",
  maxWidth: number,
  doc: any
) {
  if (!text) return [];
  const words = text.split(" ");
  let currentLine = "";
  const lines: string[] = [];

  words.forEach((word) => {
    if (doc.getStringUnitWidth(currentLine + " " + word) <= maxWidth) {
      currentLine += " " + word;
    } else {
      lines.push(currentLine.trim());
      currentLine = word;
    }
  });
  lines.push(currentLine.trim());
  return lines;
}

export const getCategories = (evaluationResponse: EvaluationResponse) => {
  return Object.keys(evaluationResponse).reduce((acc, key) => {
    const value = evaluationResponse[key as keyof EvaluationResponse];
    if (typeof value === "object" && value !== null && "summary" in value) {
      acc.push({ key, detail: value as CategoryDetail });
    }
    return acc;
  }, [] as { key: string; detail: CategoryDetail }[]);
};

export function generatePdf(
  evaluationResponse: EvaluationResponse,
  companyName: string
): void {
  const doc = new jsPDF();

  const parsedEvaluation =
    typeof evaluationResponse.evaluation === "string"
      ? JSON.parse(evaluationResponse.evaluation)
      : evaluationResponse.evaluation;

  const summary = parsedEvaluation.summary || "No summary available.";

  const categories = getCategories(parsedEvaluation);
  const maxWidth = 45;
  let yPos = 60;
  const pageHeight = 280;

  const addNewPageIfNeeded = () => {
    if (yPos > pageHeight) {
      doc.addPage();
      yPos = 10; // Reset yPos to the top of the new page
    }
  };

  doc.setFontSize(20);
  doc.text("Company Evaluation", 10, 30);

  doc.setFontSize(14);
  doc.text(`Summary Rating: ${parsedEvaluation.summary_rating}`, 10, 40);

  const evaluationSummaryLines = splitTextIntoLinesForPdf(
    summary,
    maxWidth,
    doc
  );

  doc.setFontSize(12);
  doc.text("Company Summary:", 10, 50);
  evaluationSummaryLines.forEach((line) => {
    addNewPageIfNeeded();
    doc.text(line, 10, yPos); // Print each line
    yPos += 6; // Move down for the next line
  });

  yPos += 10;

  categories.forEach((category, index) => {
    addNewPageIfNeeded();
    const categoryTitle = getTitleFromCamelcase(category.key);

    doc.text(`${index + 1}. ${categoryTitle}:`, 10, yPos);
    yPos += 10;

    const categoryDetailSummaryLines = splitTextIntoLinesForPdf(
      category.detail.summary,
      maxWidth,
      doc
    );

    categoryDetailSummaryLines.forEach((line) => {
      addNewPageIfNeeded();
      doc.text(line, 10, yPos);
      yPos += 6;
    });

    yPos += 5;

    addNewPageIfNeeded();
    doc.text(`Category Grade: ${category.detail.category_grade}`, 10, yPos);
    yPos += 10;

    const categoryDetailNoteLines = splitTextIntoLinesForPdf(
      category.detail.note,
      maxWidth,
      doc
    );

    categoryDetailNoteLines.forEach((line) => {
      addNewPageIfNeeded();
      doc.text(line, 10, yPos);
      yPos += 6;
    });
    yPos += 10;
  });

  doc.save(`${companyName}--${evaluationResponse.question_list_name}.pdf`);
}

function splitTextIntoLinesForWord(
  text: string = "",
  maxCharsPerLine: number
): string[] {
  const words = text.split(" ");
  let currentLine = "";
  const lines: string[] = [];

  words.forEach((word) => {
    if ((currentLine + " " + word).trim().length <= maxCharsPerLine) {
      currentLine += " " + word;
    } else {
      lines.push(currentLine.trim());
      currentLine = word;
    }
  });
  lines.push(currentLine.trim());
  return lines;
}

export function generateWord(
  evaluationResponse: EvaluationResponse,
  companyName: string
): void {
  const parsedEvaluation =
    typeof evaluationResponse.evaluation === "string"
      ? JSON.parse(evaluationResponse.evaluation)
      : evaluationResponse.evaluation;

  const categories = getCategories(parsedEvaluation);
  const maxCharsPerLine = 80;

  const createParagraph = (
    text: string,
    bold: boolean = false,
    size: number = 24
  ) =>
    new Paragraph({
      children: [new TextRun({ text, bold, size })],
    });

  const children: Paragraph[] = [
    createParagraph("Company Evaluation", true, 40),
    createParagraph(
      `Summary Rating: ${
        parsedEvaluation.summaryRating || "No rating available"
      }`,
      false,
      28
    ),
    createParagraph("Company Summary:", true, 28),
  ];

  // Adding the company summary
  const evaluationSummaryLines = splitTextIntoLinesForWord(
    evaluationResponse.summary,
    maxCharsPerLine
  );
  evaluationSummaryLines.forEach((line) => {
    children.push(createParagraph(line, false, 24));
  });

  // Adding categories
  categories.forEach((category, index) => {
    const categoryTitle = getTitleFromCamelcase(category.key);
    children.push(createParagraph(`${index + 1}. ${categoryTitle}:`, true, 28));

    const categoryDetailSummaryLines = splitTextIntoLinesForWord(
      category.detail.summary,
      maxCharsPerLine
    );
    categoryDetailSummaryLines.forEach((line) => {
      children.push(createParagraph(line, false, 24));
    });

    children.push(
      createParagraph(
        `Category Grade: ${category.detail.category_grade}`,
        true,
        28
      )
    );

    const categoryDetailNoteLines = splitTextIntoLinesForWord(
      category.detail.note,
      maxCharsPerLine
    );
    categoryDetailNoteLines.forEach((line) => {
      children.push(createParagraph(line, false, 24));
    });
  });

  const doc = new Document({
    sections: [
      {
        properties: {},
        children: children,
      },
    ],
  });

  Packer.toBlob(doc).then((blob) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${companyName}--${evaluationResponse.question_list_name}.docx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  });
}
