import React from "react";
import Evaluation from "../Evaluation/Evaluation";
import { CompanyStatus } from "../../Constants/Enums/companyStatus";
import { CompanyInfo } from "../../Models/company";
import { useReviewQuestions } from "../../Hooks/useReviewQuestions";
import { Button, Grid } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import color from "../../Constants/colors";
import { useNavigate } from "react-router-dom";
import { EvaluationResponse } from "../../Models/evaluation";
import { updateAboutCompany } from "../../Services/Https/company";

interface CompanyStatusContentProps {
  company: CompanyInfo | undefined;
  selectedCompanyId: number | "";
  questionListId: string;
  certificationId: string;
  languageId: number;
}

const CompanyStatusContent: React.FC<CompanyStatusContentProps> = ({
  company,
  selectedCompanyId,
  questionListId,
  certificationId,
  languageId,
}) => {
  const evaluationResponse = company?.evaluations.find(
    (evaluation) =>
      evaluation.question_list_id === parseInt(questionListId) &&
      evaluation.language === languageId
  );
  const evaluationAnswers = evaluationResponse?.answers ?? [];
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const evaluationId = evaluationResponse?.evaluation_id ?? "";

  const {
    answers,
    handleAnswerChange,
    handleFileChange,
    questionList,
    groupedAnswers,
    handleSubmitUpdateAboutCompany,
  } = useReviewQuestions(
    parseInt(questionListId),
    parseInt(certificationId),
    selectedCompanyId.toString(),
    JSON.stringify(evaluationAnswers),
    company ?? ({} as CompanyInfo)
  );

  const navigate = useNavigate();

  const handleSaveAnswers = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const dataToSend = {
        evaluation_id: evaluationId.toString(),
        answers: groupedAnswers,
      };
      await updateAboutCompany(selectedCompanyId.toString(), dataToSend);

      enqueueSnackbar("Company Updated Successfully", {
        variant: "success",
      });
      navigate("/company");
    } catch {
      enqueueSnackbar("Failed to Update Company", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAnswerChangeWrapper = (questionName: string, answer: string) => {
    const category_key = questionList?.categories.find((category) =>
      category.questions.some((q) => q.question_key === questionName)
    )?.question_category_key;

    if (category_key) {
      handleAnswerChange(questionName, answer, category_key);
    }
  };

  const parsedEvaluationResponse = evaluationResponse
    ? JSON.parse(evaluationResponse.evaluation as string)
    : null;

  const moreInfoExists = parsedEvaluationResponse?.more_info;

  if (parsedEvaluationResponse) {
    return (
      <>
        <Evaluation
          evaluationResponse={parsedEvaluationResponse as EvaluationResponse}
          questionList={questionList}
          answers={answers}
          handleAnswerChange={handleAnswerChangeWrapper}
          handleFileChange={handleFileChange}
          handleSubmitUpdateAboutCompany={handleSubmitUpdateAboutCompany}
          resetAnswers={false}
          isQuestionFieldDisabled={
            company?.status !== CompanyStatus.NEEDS_MORE_INFO
          }
          onAnswerChange={() => {}}
        />
        {moreInfoExists && (
          <Grid
            mt={5}
            sx={{
              display: "flex",
              gap: "1rem",
              justifyContent: "center",
              position: "sticky",
              bottom: 0,
              backgroundColor: color.secondaryDark,
              padding: "1rem",
              zIndex: 1000,
              boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveAnswers}
              sx={{
                backgroundColor: color.btn_dark,
                color: "white",
                "&:hover": {
                  backgroundColor: color.primary_dark,
                  color: color.btn_dark,
                },
              }}
            >
              Submit answers
            </Button>
          </Grid>
        )}
      </>
    );
  }

  return null;
};

export default CompanyStatusContent;
