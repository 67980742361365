export enum CompanyStatus {
  WAITING_FOR_EVALUATION = 0,
  EVALUATED = 1,
  NEEDS_MORE_INFO = 2,
  EVALUATION_IN_PROGRESS = 3,
  ERROR = 4,
  GATHERING_DIGITAL_PERFORMANCE = 5,
  FETCHING_COMPANY_DETAILS = 6,
  PROCESSING_COMPANY_INFORMATIONS = 7,
  GRADING_COMPANY = 8,
  TIME_LIMIT_EXCEEDED = 9,
}
export enum FilteredContentCase {
  ALL = 0,
  ABOUT = 1,
  DOCUMENT = 2,
  WEBSITE = 3,
}

export enum WebstiteStatus {
  WAITING = 0,
  IN_PROGRESS = 1,
  PROCESSED = 2,
  ERROR = 3,
}
