import {
  CheckProgress,
  CompanyInfo,
  CompanyResponse,
  CompanyStatusInterface,
  DocumentsResponse,
  WebsiteResponse,
} from "../../Models/company";
import axios from "axios";
import { enqueueSnackbar } from "notistack";

export const fetchCompanies = async (
  page: number = 1,
  page_size: number = 10,
  sort_order: string = "name",
  sort_direction: string = "asc",
  search: string = ""
): Promise<CompanyResponse> => {
  const { data } = await axios.get<CompanyResponse>(
    `${process.env.REACT_APP_BASE_URL}/v1/company/`,
    {
      withCredentials: true,
      params: { page, page_size, sort_order, sort_direction, search },
    }
  );
  return data;
};

export const getSingleCompany = async (id: string): Promise<CompanyInfo> => {
  const { data } = await axios.get<CompanyInfo>(
    `${process.env.REACT_APP_BASE_URL}/v1/company/${id}/`,
    { withCredentials: true }
  );
  return data;
};

export const checkProgress = async () => {
  const { data } = await axios.get<CheckProgress>(
    `${process.env.REACT_APP_BASE_URL}/v1/company/check_progress/`,
    { withCredentials: true }
  );
  return data;
};

export const getCompanyStatus = async (id: string) => {
  const { data } = await axios.get<CompanyStatusInterface>(
    `${process.env.REACT_APP_BASE_URL}/v1/company/status/${id}/`,
    { withCredentials: true }
  );
  return data;
};

export const deleteSingleCompany = async (id: string) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/v1/company/${id}/`,
    { withCredentials: true }
  );
  return response;
};

export const updateSingleCompany = async (
  id: string,
  name: string = "",
  about: string = "",
  location: string = "",
  industry: string = ""
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BASE_URL}/v1/company/update/${id}/`,

    {
      name: name,
      about: about,
      location: location,
      industry: industry,
    },
    { withCredentials: true }
  );
  return response;
};

export const updateSingleCompanyName = async (
  id: string,
  name: string = ""
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BASE_URL}/v1/company/update/${id}/`,
    {
      name: name,
    },
    {
      withCredentials: true,
    }
  );
  return response;
};

export const updateAboutCompany = async (
  id: string,
  data: { evaluation_id: string; answers: any }
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BASE_URL}/v2/company/update_answers/${id}/`,
    {
      evaluation_id: data.evaluation_id,
      answers: data.answers,
    },
    {
      withCredentials: true,
    }
  );
  return response;
};

export const createCompany = async (
  name: string,
  about: string,
  industry: string,
  location: string
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v1/company/create/`,
    {
      name: name,
      about: about,
      location: location,
      industry: industry,
    },
    {
      withCredentials: true,
    }
  );
  return response;
};

export const getCompanyWebsites = async (
  companyId: string,
  page: number = 1,
  page_size: number = 10,
  sort_order: string = "name",
  sort_direction: string = "asc",
  search: string = ""
) => {
  const response = await axios.get<WebsiteResponse>(
    `${process.env.REACT_APP_BASE_URL}/v1/website/company/${companyId}/`,
    {
      withCredentials: true,
      params: { page, page_size, sort_order, sort_direction, search },
    }
  );
  return response.data;
};

export const uploadWebsite = async (
  companyId: string,
  name: string = " ",
  web_adress: string
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v1/website/create/`,

    {
      company_id: companyId,
      name: name,
      web_adress: web_adress,
    },
    { withCredentials: true }
  );
  return response;
};

export const uploadFile = async (formData: FormData) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v1/document/`,
    formData,
    { withCredentials: true }
  );
  return response;
};

export const downloadCompanyDocument = async (id: string) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/v1/document/${id}/`,
    {
      withCredentials: true,
    }
  );
  return response;
};

export const downloadScrappedDocumentContent = async (
  documentId: number,
  fileNames: { [key: number]: string },
  setIsDownloadingText: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsDownloadingText(true);
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v1/document/${documentId}/filtered/`,
      {
        withCredentials: true,
        responseType: "blob",
      }
    );

    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement("a");
    fileLink.href = fileURL;

    const contentDisposition = response.headers["content-disposition"];
    let fileName = "download.txt";
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
      if (fileNameMatch && fileNameMatch.length === 2) {
        fileName = fileNameMatch[1];
      }
    }

    if (fileNames[documentId]) {
      fileName = `${fileNames[documentId].split(".")[0]}.txt`;
    }

    fileLink.setAttribute("download", fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
  } catch (error) {
    enqueueSnackbar("Failed to download scraped content", {
      variant: "error",
    });
  } finally {
    setIsDownloadingText(false);
  }
};

export const downloadScrappedWebsiteContent = async (websiteId: number) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v1/website/${websiteId}/scraped/`,
      {
        withCredentials: true,
        responseType: "blob",
      }
    );

    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement("a");
    fileLink.href = fileURL;

    const contentDisposition = response.headers["content-disposition"];
    let fileName = "download.txt";
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
      if (fileNameMatch && fileNameMatch.length === 2) {
        fileName = fileNameMatch[1];
      }
    }

    fileLink.setAttribute("download", fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
  } catch (error) {
    enqueueSnackbar("Failed to download scraped content", {
      variant: "error",
    });
  }
};

// const extensionToMIME: Record<string, string> = {
//   'pdf': 'application/pdf',
//   'jpg': 'image/jpeg',
//   'jpeg': 'image/jpeg',
//   'png': 'image/png',
//   'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//   'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
// };

function extensionType(extension: string): string {
  if (extension === "pdf") return "application/pdf";
  if (extension === "docx")
    return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  if (extension === "png") return "image/png";
  if (extension === "jpeg" || extension === "jpg") return "image/jpeg";
  return "";
}

export const downloadDocument = async (
  documentId: string,
  extension: string,
  setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsDownloading(true);
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v1/document/${documentId}/`,
      {
        withCredentials: true,
        responseType: "blob",
      }
    );

    const fileURL = window.URL.createObjectURL(
      new Blob([response.data], { type: extensionType(extension) })
    );
    const fileLink = document.createElement("a");
    fileLink.href = fileURL;

    const contentDisposition = response.headers["content-disposition"];
    let fileName = "download." + extension;
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
      if (fileNameMatch && fileNameMatch.length === 2) {
        fileName = fileNameMatch[1];
      }
    }

    fileLink.setAttribute("download", fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
  } catch (error) {
    enqueueSnackbar("Failed to download document", {
      variant: "error",
    });
  } finally {
    setIsDownloading(false);
  }
};

export const deleteDocument = async (id: number) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/v1/document/${id}/`,
    { withCredentials: true }
  );
  return response;
};

export const deleteWebsite = async (id: number) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/v1/website/${id}/`,
    {
      withCredentials: true,
    }
  );
  return response;
};

export const fetchDocuments = async (
  companyId: string,
  page: number = 1,
  page_size: number = 10,
  sort_order: string = "name",
  sort_direction: string = "asc"
): Promise<DocumentsResponse> => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/v1/document/company/${companyId}/`,
    {
      withCredentials: true,
      params: { page, page_size, sort_order, sort_direction },
    }
  );
  return response.data;
};
