import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { styled, keyframes } from "@mui/system";
import color from "../../Constants/colors";
import { useUploadCSVFile } from "../../Hooks/useDussman";

const OuterWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100vw",
  height: "100vh",
  overflow: "hidden",
}));

const Section = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  background: `linear-gradient(to bottom, rgba(3, 46, 77, 0.85), rgba(6, 70, 116, 0.85)), url(/background.png)`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  color: color.white,
  textAlign: "center",
  padding: theme.spacing(5),
  width: "100vw",
  overflow: "hidden",
  position: "relative",
  animation: "fadeIn 2s ease-in",
  "@keyframes fadeIn": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
}));

const DecorativeLine = styled(Box)(({ theme }) => ({
  height: "4px",
  width: "110px",
  backgroundColor: color.accent,
  margin: "10px auto",
  borderRadius: "2px",
  animation: "lineGrow 1s ease-in-out",
  "@keyframes lineGrow": {
    from: { width: 0 },
    to: { width: "110px" },
  },
}));

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(Box)(({ theme }) => ({
  width: "50px",
  height: "50px",
  border: `6px solid rgba(255, 255, 255, 0.3)`,
  borderTopColor: color.accent,
  borderRadius: "50%",
  animation: `${rotate} 1s linear infinite`,
  marginTop: theme.spacing(2),
}));

const CSVUpload: React.FC = () => {
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const { mutate: uploadFile, isLoading } = useUploadCSVFile();
  const [isFirstUpload, setIsFirstUpload] = useState<boolean>(true);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setIsFirstUpload(false);
      setUploadedFileName(file.name);
      uploadFile(file);
    }
  };

  return (
    <OuterWrapper>
      <Section>
        <DecorativeLine />
        <Typography variant="h2" gutterBottom>
          Dussmann Knowledge Import
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ color: color.btn_dark }}>
          Upload your export file to automatically enhance your Alan AI
          knowledge collection.
        </Typography>

        <Button
          variant="contained"
          component="label"
          disabled={isLoading}
          sx={{
            marginTop: 3,
            padding: "10px 20px",
            borderRadius: "30px",
            backgroundColor: isLoading
              ? color.btn_dark_disabled
              : color.btn_dark,
            color: color.white,
            textAlign: "center",
            textDecoration: "none",
            "&:hover": {
              backgroundColor: isLoading
                ? color.btn_dark_disabled
                : color.primary_dark,
              color: color.btn_dark,
            },
          }}
          startIcon={<UploadFileIcon />}
        >
          {isFirstUpload ? "Upload a CSV file" : "Upload another CSV file"}
          <input type="file" hidden accept=".csv" onChange={handleFileUpload} />
        </Button>

        {isLoading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 3,
              animation: "fadeIn 1.5s ease-in-out",
            }}
          >
            <Spinner />
            <Typography
              variant="h6"
              sx={{
                marginTop: 2,
                color: color.white,
                fontWeight: "bold",
                textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
              }}
            >
              {uploadedFileName}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: color.accent,
                marginTop: 1,
                animation: "pulse 1s infinite",
                "@keyframes pulse": {
                  "0%": { transform: "scale(1)" },
                  "50%": { transform: "scale(1.05)" },
                  "100%": { transform: "scale(1)" },
                },
              }}
            >
              Processing your data securely...
            </Typography>
          </Box>
        )}
      </Section>
    </OuterWrapper>
  );
};

export default CSVUpload;
