import axios from "axios";

export const uploadCSVFile = async (file: any) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v1/dussmann/upload_kb/`,
    formData,
    {
      withCredentials: true,
    }
  );
  return response;
};
