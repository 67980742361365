
export const languageMappings: { [key: string]: number } = {
  English: 0,
  German: 1,
  Serbian: 2,
  Greek: 3,
  Hungarian: 4,
  Japanese: 5,
};

export const languageIDs = Object.keys(languageMappings).reduce((acc, lang) => {
  acc[languageMappings[lang]] = lang;
  return acc;
}, {} as { [key: number]: string });
