import React from "react";
import { Grid, Paper, Typography, CircularProgress } from "@mui/material";
import color from "../../Constants/colors";
import { ProcessingStatus } from "../../Models/user";
import { getFileIcon, truncateString } from "../../helpers/helpers";

interface UploadedFilesPanelProps<T> {
  file: T;
  getFileName: (file: T) => string;
  getFileStatus: (file: T) => number;
}

const UploadedFilesPanel = <T,>({
  file,
  getFileName,
  getFileStatus,
}: UploadedFilesPanelProps<T>) => {
  const fileName = getFileName(file);
  const fileStatus = getFileStatus(file);
  return (
    <Grid
      item
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mb: 2,
        maxWidth: 120,
      }}
      maxWidth={200}
    >
      <Paper
        variant="outlined"
        sx={{
          width: 80,
          height: 80,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          overflow: "hidden",
          padding: 1,
          "&:hover .remove-icon": {
            display: "flex",
          },
          opacity: fileStatus === ProcessingStatus.IN_PROGRESS ? 0.5 : 1,
          "& .MuiSvgIcon-root": {
            height: "100%",
            width: "100%",
            maxHeight: 100,
            maxWidth: 100,
          },
        }}
      >
        {getFileIcon(fileName)}
        {fileStatus === ProcessingStatus.IN_PROGRESS && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
              color: color.btn_dark,
            }}
          />
        )}
      </Paper>

      <Typography
        variant="caption"
        sx={{
          color: color.btn_dark,
          fontWeight: "bold",
          marginTop: "3px",
          textAlign: "center",
          maxWidth: 100,
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        title={fileName}
      >
        {truncateString(fileName, 18)}
      </Typography>
    </Grid>
  );
};

export default UploadedFilesPanel;
