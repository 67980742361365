import React from "react";
import {
  Box,
  Collapse,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import color from "../../Constants/colors";
import Loader from "../UI/Loader";
import { GetAllKnowledgeBasesResponse } from "../../Models/knowledge";

interface KnowledgeBaseSectionProps {
  knowledgeBaseIds: number[];
  knowledgeBaseList: GetAllKnowledgeBasesResponse[] | undefined;
  knowledgeBaseListLoading: boolean;
  knowledgeBaseListError: boolean;
  toggleKnowledgeBaseSection: () => void;
  handleKnowledgeBaseToggle: (id: number) => void;
  openKnowledgeBaseSection: boolean;
}

const KnowledgeBaseSection: React.FC<KnowledgeBaseSectionProps> = ({
  knowledgeBaseIds,
  knowledgeBaseList,
  knowledgeBaseListLoading,
  knowledgeBaseListError,
  toggleKnowledgeBaseSection,
  handleKnowledgeBaseToggle,
  openKnowledgeBaseSection,
}) => {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        mt={2}
        onClick={toggleKnowledgeBaseSection}
        style={{ cursor: "pointer" }}
      >
        <Typography variant="body1" style={{ color: color.white }}>
          Attach Knowledge Bases
        </Typography>
        {openKnowledgeBaseSection ? (
          <ExpandLessIcon sx={{ color: color.btn_dark }} />
        ) : (
          <ExpandMoreIcon sx={{ color: color.btn_dark }} />
        )}
      </Box>
      <Collapse in={openKnowledgeBaseSection} timeout="auto" unmountOnExit>
        <Box mt={2} display="flex" flexDirection="row" justifyContent="center">
          <Grid
            container
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            flexWrap="wrap"
            border="1px solid rgba(255,255,255,0.3)"
            padding="1rem"
            color={color.white}
          >
            {knowledgeBaseListLoading ? (
              <Loader message="Loading Knowledge Bases..." />
            ) : knowledgeBaseListError ? (
              <Typography variant="body1" color="error">
                Error loading knowledge bases.
              </Typography>
            ) : (
              knowledgeBaseList?.map((kb) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={3}
                  key={kb.id}
                  sx={{ fontSize: "1rem" }}
                >
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Checkbox
                        checked={knowledgeBaseIds.includes(kb.id)}
                        onChange={() => handleKnowledgeBaseToggle(kb.id)}
                      />
                    }
                    label={kb.name}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      </Collapse>
    </>
  );
};

export default KnowledgeBaseSection;
