import React, { useState } from "react";
import {
  Button,
  Typography,
  Box,
  Container,
  Grid,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material";
import colors from "../../Constants/colors";
import { ExpertFormState } from "../../Models/form";
import FormTextField from "./FormTextField";
import SkillsAndAbilities from "./SkillsAndAbilities";
import RolesAndResponsibilities from "./RolesAndResponsibilities";
import KnowledgeBaseAndCurrentTrends from "./KnowledgeBaseAndCurrentTrends";
import ModelBehavior from "./ModelBehavior";
import { createExpert } from "../../Services/Https/expertForm";
import { useSnackbar } from "notistack";
import { handleAxiosError } from "../../Services/Https/errorHandler";
import { useNavigate } from "react-router-dom";

const initialFormState: ExpertFormState = {
  expertName: "",
  professionalBackground: "",
  keyExpertise: "",
  trainingAndCertifications: "",
  processMastery: "",
  primaryResponsibilities: "",
  problemSolvingAbilities: "",
  knowledgeDepth: "",
  knowledgebase: [],
  trends: [],
  modelBehavior: "consistent",
};

const ExpertForm: React.FC = () => {
  const [formState, setFormState] = useState<ExpertFormState>(initialFormState);
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleLinkChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    section: "knowledgebase" | "trends",
    index: number
  ) => {
    const { value } = e.target;
    const updatedLinks = [...formState[section]];
    updatedLinks[index] = value;
    setFormState((prevState) => ({
      ...prevState,
      [section]: updatedLinks,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [`${section}-${index}`]: "",
    }));
  };

  const handleAddLink = (section: "knowledgebase" | "trends") => {
    setFormState((prevState) => ({
      ...prevState,
      [section]: [...prevState[section], ""],
    }));
  };

  const handleRemoveLink = (
    section: "knowledgebase" | "trends",
    index: number
  ) => {
    const updatedLinks = [...formState[section]];
    updatedLinks.splice(index, 1);
    setFormState((prevState) => ({
      ...prevState,
      [section]: updatedLinks,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors: { [key: string]: string } = {};

    Object.keys(formState).forEach((key) => {
      if (!formState[key as keyof ExpertFormState]) {
        newErrors[key] = "Required field";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setValidationErrors(newErrors);
      return;
    }

    setIsLoading(true);

    try {
      await createExpert(formState);
      setFormState(initialFormState);
      enqueueSnackbar("Expert created successfully.", {
        variant: "success",
      });
      setSubmissionSuccess(true);
    } catch (error: any) {
      const errorMessage = handleAxiosError(error);
      setError(error);
      enqueueSnackbar(`Error creating expert: ${errorMessage}`, {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        Error: {error}
      </Box>
    );
  }

  if (submissionSuccess) {
    return (
      <Container maxWidth="lg">
        <Box
          sx={{ padding: 4, backgroundColor: "transparent", borderRadius: 2 }}
        >
          <Typography
            variant="h5"
            textAlign="center"
            sx={{ color: colors.white }}
          >
            Your request to create an AI expert has been successfully submitted.
            A member of our team will contact you shortly. Thank you for your
            interest!
          </Typography>

          <Box mt={2} textAlign="center">
            <Button
              onClick={() => {
                navigate("/");
              }}
              variant="contained"
              sx={{
                backgroundColor: colors.btn_dark,
                color: colors.white,
                "&:hover": {
                  backgroundColor: colors.primary_dark,
                  color: colors.btn_dark,
                },
                padding: 2,
              }}
            >
              Back to Homepage
            </Button>
          </Box>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ padding: 4, backgroundColor: "transparent", borderRadius: 2 }}>
        <Typography
          variant="h4"
          gutterBottom
          textAlign="center"
          sx={{ color: colors.white }}
        >
          Expert Specification
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 4 }}>
          <Grid container justifyContent="center">
            <FormTextField
              label="Expert Name"
              name="expertName"
              value={formState.expertName}
              onChange={handleChange}
              info="Enter the name for your expert. For example: Tom"
              minRows={1}
              error={!!validationErrors.expertName}
              helperText={validationErrors.expertName}
            />
            <SkillsAndAbilities
              formState={formState}
              handleChange={handleChange}
              validationErrors={validationErrors}
            />
            <RolesAndResponsibilities
              formState={formState}
              handleChange={handleChange}
              validationErrors={validationErrors}
            />
            <KnowledgeBaseAndCurrentTrends
              formState={formState}
              handleLinkChange={handleLinkChange}
              handleAddLink={handleAddLink}
              handleRemoveLink={handleRemoveLink}
            />
            <ModelBehavior
              value={formState.modelBehavior}
              handleChange={handleSelectChange}
              error={!!validationErrors.modelBehavior}
              helperText={validationErrors.modelBehavior}
            />
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} textAlign="center">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: colors.btn_dark,
                  color: colors.white,
                  "&:hover": {
                    backgroundColor: colors.primary_dark,
                    color: colors.btn_dark,
                  },
                  padding: 2,
                  marginTop: "50px",
                }}
              >
                <Typography variant="body1">Submit</Typography>
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default ExpertForm;
