import { CompanyStatus } from "../../Constants/Enums/companyStatus";

export const statusToString = (status: number): string => {
  switch (status) {
    case CompanyStatus.WAITING_FOR_EVALUATION:
      return "Waiting for Evaluation";
    case CompanyStatus.EVALUATED:
      return "Evaluated";
    case CompanyStatus.NEEDS_MORE_INFO:
      return "Needs More Info";
    case CompanyStatus.EVALUATION_IN_PROGRESS:
      return "Evaluation in Progress";
    case CompanyStatus.ERROR:
      return "An error occurred while processing the company status";
    case CompanyStatus.FETCHING_COMPANY_DETAILS:
      return "Getting company informations";
    case CompanyStatus.PROCESSING_COMPANY_INFORMATIONS:
      return "Processing company informations";
    case CompanyStatus.GRADING_COMPANY:
      return "Grading company";
    case CompanyStatus.TIME_LIMIT_EXCEEDED:
      return "Error: time limit exceeded";
    default:
      return "Unknown Status";
  }
};
