import { useState, useEffect, useCallback } from "react";
import { enqueueSnackbar } from "notistack";
import { QuestionListDetailsResponse } from "../Models/question";
import {
  fetchDefaultQuestionListDetails,
  fetchQuestionListDetails,
} from "../Services/Https/questions";
import { updateAboutCompany, uploadFile } from "../Services/Https/company";
import { useNavigate } from "react-router-dom";
import { CompanyInfo } from "../Models/company";
import { EvaluationResponse } from "../Models/evaluation";

export const useReviewQuestions = (
  questionListId: number | null,
  certificationId: number | null,
  companyId: string,
  initialAnswers: string,
  companyInfo: CompanyInfo
) => {
  const navigate = useNavigate();

  const [questionList, setQuestionList] =
    useState<QuestionListDetailsResponse>();
  const [answers, setAnswers] = useState<
    { question_key: string; answer: string; category_key: string }[]
  >([]);
  const [, setOriginalAnswers] = useState<
    { question_key: string; answer: string; category_key: string }[]
  >([]);

  useEffect(() => {
    if (initialAnswers) {
      try {
        const parsedAnswers = JSON.parse(initialAnswers);
        setAnswers(parsedAnswers);
        setOriginalAnswers(parsedAnswers);
      } catch (error) {
        enqueueSnackbar("Error parsing initialAnswers", { variant: "error" });
      }
    }
  }, [initialAnswers]);

  const fetchQuestionList = useCallback(async () => {
    if (!questionListId && !certificationId) return;
    try {
      if (questionListId) {
        setQuestionList(await fetchQuestionListDetails(questionListId));
      } else if (certificationId) {
        setQuestionList(await fetchDefaultQuestionListDetails(certificationId));
      }
    } catch (err) {
      enqueueSnackbar("Error fetching question list", {
        variant: "error",
      });
    }
  }, [questionListId, certificationId]);

  const handleAnswerChange = (
    question_key: string,
    answer: string,
    category_key: string
  ) => {
    setAnswers((prevAnswers) => {
      const existingAnswerIndex = prevAnswers.findIndex(
        (a) => a.question_key === question_key
      );

      if (existingAnswerIndex !== -1) {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingAnswerIndex].answer = answer;
        return updatedAnswers;
      }

      const newAnswers = [
        ...prevAnswers,
        { question_key, answer, category_key },
      ];
      return newAnswers;
    });
  };

  const answersObject = answers.reduce(
    (acc: { [key: string]: string }, answer) => {
      acc[answer.question_key] = answer.answer;
      return acc;
    },
    {}
  );

  const groupedAnswers = answers.reduce((acc, answer) => {
    if (!acc[answer.category_key]) {
      acc[answer.category_key] = {};
    }
    acc[answer.category_key][answer.question_key] = answer.answer;
    return acc;
  }, {} as { [category_key: string]: { [question_key: string]: string } });

  useEffect(() => {
    fetchQuestionList();
  }, [fetchQuestionList]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    questionName: string
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("company_id", companyId);
      formData.append("document_name", file.name);
      formData.append("file", file);

      try {
        await uploadFile(formData);
        enqueueSnackbar("Successfully uploaded file", { variant: "success" });
      } catch (err) {
        enqueueSnackbar("Failed to upload file", { variant: "error" });
      }
    }
  };

  const handleSubmitUpdateAboutCompany = async () => {
    try {
      const selectedEvaluation = companyInfo.evaluations.find(
        (evaluation: EvaluationResponse) =>
          evaluation.question_list_id === questionListId
      );

      if (selectedEvaluation) {
        const dataToSend = {
          evaluation_id: selectedEvaluation.evaluation_id.toString(),
          answers: groupedAnswers,
        };

        await updateAboutCompany(companyId, dataToSend);
        enqueueSnackbar("Company Updated Successfully", {
          variant: "success",
        });
        navigate(`/company/${companyId}`);
      } else {
        enqueueSnackbar("Evaluation not found", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Failed to Update Company", { variant: "error" });
    }
  };

  return {
    questionList,
    answers: answersObject,
    groupedAnswers,
    setAnswers,
    handleAnswerChange,
    handleFileChange,
    handleSubmitUpdateAboutCompany,
  };
};
