import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CircularProgress,
  Container,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Box,
  IconButton,
  MenuItem,
  Typography,
  ListItemIcon,
  ListItemText,
  FormControl,
  Select,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { CompanyInfo } from "../../Models/company";
import {
  getSingleCompany,
  deleteSingleCompany,
  updateSingleCompany,
  updateAboutCompany,
} from "../../Services/Https/company";
import { AxiosResponse } from "axios";
import CircleIcon from "@mui/icons-material/Circle";
import { enqueueSnackbar } from "notistack";
import Evaluation from "../Evaluation/Evaluation";
import ErrorIcon from "@mui/icons-material/Error";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadModal from "../FileUpload/FileUploadModal";
import { CompanyStatus } from "../../Constants/Enums/companyStatus";
import color from "../../Constants/colors";
import { AnswerDetail, EvaluationResponse } from "../../Models/evaluation";
import CompanyActions from "./CompanyActions";
import { useReviewQuestions } from "../../Hooks/useReviewQuestions";
import { handleAxiosError } from "../../Services/Https/errorHandler";
import { Assessment } from "@mui/icons-material";
import theme from "../../Constants/theme";
import { deleteEvaluation } from "../../Services/Https/evaluations";
import {
  fetchDefaultQuestionListDetails,
  fetchQuestionListDetails,
} from "../../Services/Https/questions";
import { QuestionListDetailsResponse } from "../../Models/question";
import { languageMappings } from "../../Constants/languageMappings";

const SingleCompanyComponent: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo | null>(null);
  const [originalCompanyInfo, setOriginalCompanyInfo] =
    useState<CompanyInfo | null>(null);
  const [resetAnswers, setResetAnswers] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [disablingEdit, setDisablingEdit] = useState<boolean>(true);
  const [isFileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const [visibility, setVisibility] = useState<"visible" | "hidden">("visible");
  const evaluationRef = useRef<HTMLDivElement>(null);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const navigate = useNavigate();
  const [responseName, setResponseName] = useState<string>("");
  const [responseAbout, setResponseAbout] = useState<string>("");
  const [responseIndustry, setResponseIndustry] = useState<string>("");
  const [responseLocation, setResponseLocation] = useState<string>("");
  const [, setResponseAnswers] = useState<{
    [key: string]: string;
  }>({});
  const [evaluationInProgress, setEvaluationInProgress] = useState<
    boolean | null
  >(null);
  const [questionList, setQuestionList] = useState<
    QuestionListDetailsResponse | undefined
  >(undefined);
  const [selectedEvaluationId, setSelectedEvaluationId] = useState<
    number | null
  >(null);
  const [originalAnswers, setOriginalAnswers] = useState<{
    [question_key: string]: string;
  }>({});
  const [, setSelectedEvaluation] = useState<EvaluationResponse | null>(null);

  const questionListId =
    companyInfo?.last_evaluation_request?.question_list?.id ?? 0;

  const certificationId = 0;

  const centerAltReturnsStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  };

  const {
    answers,
    handleAnswerChange,
    handleFileChange,
    setAnswers,
    handleSubmitUpdateAboutCompany,
  } = useReviewQuestions(
    questionListId,
    certificationId,
    String(companyInfo?.id),
    selectedEvaluationId
      ? JSON.stringify(
          companyInfo?.evaluations.find(
            (e) => e.evaluation_id === selectedEvaluationId
          )?.answers ?? []
        )
      : "",
    companyInfo ?? ({} as CompanyInfo)
  );

  const fetchCompanyInfo = useCallback(async (id: string) => {
    setLoading(true);
    try {
      const data: CompanyInfo = await getSingleCompany(id);
      setEvaluationInProgress(
        data.status === CompanyStatus.EVALUATION_IN_PROGRESS ||
          data.status === CompanyStatus.FETCHING_COMPANY_DETAILS ||
          data.status === CompanyStatus.PROCESSING_COMPANY_INFORMATIONS ||
          data.status === CompanyStatus.GRADING_COMPANY
      );
      setCompanyInfo(data);
      setOriginalCompanyInfo(data);

      const firstEvaluationId =
        data.evaluations.length > 0 ? data.evaluations[0].evaluation_id : null;
      setSelectedEvaluationId(firstEvaluationId);
      setResponseName(data.name);
      setResponseAbout(data.about);
      setResponseIndustry(data.industry);
      setResponseLocation(data.location);
      if (firstEvaluationId) {
        const selectedEvaluation = data.evaluations.find(
          (e) => e.evaluation_id === firstEvaluationId
        );

        const formattedAnswers =
          selectedEvaluation?.answers.reduce(
            (acc: { [key: string]: string }, answer: AnswerDetail) => {
              acc[answer.question_key] = answer.answer;
              return acc;
            },
            {}
          ) ?? {};

        setResponseAnswers(formattedAnswers);
        setOriginalAnswers(formattedAnswers);
      } else {
        setResponseAnswers({});
      }
    } catch (error) {
      const errorMessage = handleAxiosError(error);
      enqueueSnackbar(`Error fetching company: ${errorMessage}`, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (companyId) {
      fetchCompanyInfo(companyId);
    } else {
      setError("No company ID provided");
      setLoading(false);
    }
  }, [companyId, fetchCompanyInfo]);

  useEffect(() => {
    if (questionList) {
    }
  }, [questionList]);

  const fetchQuestionList = useCallback(async () => {
    if (!questionListId && !certificationId) return;
    try {
      let list;
      if (questionListId) {
        list = await fetchQuestionListDetails(questionListId);
      } else if (certificationId) {
        list = await fetchDefaultQuestionListDetails(certificationId);
      }
      if (list) {
        setQuestionList(list);
      } else {
        setQuestionList(undefined);
      }
    } catch (err) {
      enqueueSnackbar("Error fetching question list", {
        variant: "error",
      });
    }
  }, [questionListId, certificationId]);

  useEffect(() => {
    if (questionListId || certificationId) {
      fetchQuestionList();
    }
  }, [questionListId, certificationId, fetchQuestionList]);

  const handleCancel = () => {
    if (originalCompanyInfo) {
      setCompanyInfo({ ...originalCompanyInfo });
      setResponseName(originalCompanyInfo.name);
      setResponseAbout(originalCompanyInfo.about);
      setResponseIndustry(originalCompanyInfo.industry);
      setResponseLocation(originalCompanyInfo.location);
      setAnswers(
        selectedEvaluationId
          ? originalCompanyInfo.evaluations
              .find((e) => e.evaluation_id === selectedEvaluationId)
              ?.answers.map((answer) => ({
                question_key: answer.question_key,
                answer: answer.answer,
                category_key:
                  questionList?.categories.find((category) =>
                    category.questions.some(
                      (q) => q.question_key === answer.question_key
                    )
                  )?.question_category_key || "",
              })) ?? []
          : []
      );
      setIsSaveButtonEnabled(false);
      setResetAnswers(true);
      setDisablingEdit(!disablingEdit);
    }
  };

  useEffect(() => {
    if (resetAnswers) {
      setResetAnswers(false);
    }
  }, [resetAnswers]);

  if (loading) {
    return (
      <Box sx={centerAltReturnsStyle}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Box sx={centerAltReturnsStyle}>Error: {error}</Box>;
  }
  if (!companyInfo) {
    return (
      <Box sx={centerAltReturnsStyle}>
        <div>No company information found</div>
      </Box>
    );
  }

  const handleSave = async (id: string) => {
    setLoading(true);
    try {
      const { name, about, industry, location } = companyInfo;

      if (name.trim() === "" || about.trim() === "") {
        enqueueSnackbar('"Name" and "About" fields cannot be empty.', {
          variant: "warning",
        });
        setLoading(false);
        return;
      }

      const changedAnswers = Object.keys(answers).reduce((acc, questionKey) => {
        if (answers[questionKey] !== originalAnswers[questionKey]) {
          acc[questionKey] = answers[questionKey];
        }
        return acc;
      }, {} as { [key: string]: string });

      const isCompanyInfoChanged =
        about !== responseAbout ||
        name !== responseName ||
        industry !== responseIndustry ||
        location !== responseLocation;

      if (!isCompanyInfoChanged && Object.keys(changedAnswers).length === 0) {
        enqueueSnackbar("No changes detected", { variant: "info" });
      } else {
        let isUpdated = false;

        if (isCompanyInfoChanged) {
          await updateSingleCompany(id, name, about, location, industry);
          enqueueSnackbar("Company Updated Successfully", {
            variant: "success",
          });
          isUpdated = true;
        }

        if (Object.keys(changedAnswers).length > 0 && companyId) {
          const changedGroupedAnswers = Object.keys(changedAnswers).reduce(
            (acc, questionKey) => {
              const category_key = questionList?.categories.find((category) =>
                category.questions.some((q) => q.question_key === questionKey)
              )?.question_category_key;

              if (category_key) {
                if (!acc[category_key]) {
                  acc[category_key] = {};
                }
                acc[category_key][questionKey] = changedAnswers[questionKey];
              }
              return acc;
            },
            {} as { [category_key: string]: { [question_key: string]: string } }
          );

          const dataToSend = {
            evaluation_id: selectedEvaluationId
              ? selectedEvaluationId.toString()
              : "",
            answers: changedGroupedAnswers,
          };

          await updateAboutCompany(companyId, dataToSend);
          enqueueSnackbar("Answers Updated Successfully", {
            variant: "success",
          });
          isUpdated = true;

          setOriginalAnswers((prevOriginalAnswers) => ({
            ...prevOriginalAnswers,
            ...changedAnswers,
          }));
        }

        if (isUpdated) {
          setResponseAbout(about);
          setResponseName(name);
          setResponseIndustry(industry);
          setResponseLocation(location);
        }
      }
    } catch (err) {
      setError("Failed to update company");
      enqueueSnackbar("Failed to Update Company", { variant: "error" });
    } finally {
      setLoading(false);
      setDisablingEdit(true);
      setVisibility("visible");
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      if (companyId !== undefined) {
        const response: AxiosResponse<CompanyInfo> = await deleteSingleCompany(
          companyId
        );
        setCompanyInfo(response.data);
        enqueueSnackbar("Deleted Company Successfully", { variant: "success" });
        navigate("/company");
      }
    } catch (err) {
      setError("Failed to delete company");
      enqueueSnackbar("Didn't Delete Company Successfully", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = () => {
    setDisablingEdit(!disablingEdit);
  };

  const handleScrollToComponent = () => {
    evaluationRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  if (!companyId) {
    return <Grid>Invalid address</Grid>;
  }

  const handleOpenFileUploadModal = () => {
    setFileUploadModalOpen(true);
  };

  const handleCloseFileUploadModal = () => {
    setFileUploadModalOpen(false);
  };

  const handleFieldChange = () => {
    setIsSaveButtonEnabled(true);
  };

  const handleEvaluationChange = async (
    event: SelectChangeEvent<number | string>
  ) => {
    const selectedValue = event.target.value;

    if (selectedValue === "start-evaluation") {
      navigate("/evaluation");
      return;
    }

    const evaluationId = Number(selectedValue);
    setSelectedEvaluationId(evaluationId);

    const evaluation = companyInfo?.evaluations.find(
      (evaluation) => evaluation.evaluation_id === evaluationId
    );

    setSelectedEvaluation(evaluation || null);

    if (evaluation?.question_list_id) {
      try {
        const list = await fetchQuestionListDetails(
          evaluation.question_list_id
        );
        setQuestionList(list);
      } catch (err) {
        enqueueSnackbar("Failed to fetch question list for new evaluation.", {
          variant: "error",
        });
      }
    } else {
      setQuestionList(undefined);
    }
  };

  const handleEvaluationData = (evaluation: any) => {
    try {
      return JSON.parse(evaluation.evaluation);
    } catch (e) {
      enqueueSnackbar(`Failed to parse evaluation data`, {
        variant: "error",
      });
      return null;
    }
  };

  const handleDeleteEvaluation = async (evaluationId: number) => {
    try {
      await deleteEvaluation(String(evaluationId));
      enqueueSnackbar("Deleted Evaluation Successfully", {
        variant: "success",
      });

      if (companyId) {
        fetchCompanyInfo(companyId);
      }
    } catch (error) {
      enqueueSnackbar("Failed to Delete Evaluation", { variant: "error" });
    }
  };

  const handleAnswerChangeWrapper = async (
    question_key: string,
    answer: string
  ) => {
    if (!questionList) {
      try {
        const list = questionListId
          ? await fetchQuestionListDetails(questionListId)
          : await fetchDefaultQuestionListDetails(certificationId);

        if (list) {
          setQuestionList(list);
        } else {
          enqueueSnackbar("Failed to fetch question list.", {
            variant: "error",
          });
          return;
        }
      } catch (error) {
        enqueueSnackbar("Error fetching question list", {
          variant: "error",
        });
        return;
      }
    }

    const category = questionList?.categories.find((category) =>
      category.questions.some((q) => q.question_key === question_key)
    );

    if (category) {
      handleAnswerChange(question_key, answer, category.question_category_key);
    } else {
      enqueueSnackbar(`No category found for question_key: ${question_key}.`, {
        variant: "warning",
      });
    }
  };

  return (
    <>
      <Container>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          padding={"0 16px"}
          sx={{
            flexDirection: { xs: "column-reverse", md: "row" },
            marginBottom: 2,
            alignItems: { xs: "space-between", md: "center" },
          }}
        >
          <Grid item>
            <TextField
              value={companyInfo.name}
              label="Name of company"
              disabled={disablingEdit}
              onChange={(e) => {
                setCompanyInfo({ ...companyInfo, name: e.target.value });
                handleFieldChange();
              }}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {companyInfo?.status ===
                      CompanyStatus.WAITING_FOR_EVALUATION ||
                    companyInfo?.status === CompanyStatus.NEEDS_MORE_INFO ||
                    companyInfo?.status === CompanyStatus.EVALUATED ? (
                      <CircleIcon
                        sx={{
                          color:
                            companyInfo?.status ===
                            CompanyStatus.WAITING_FOR_EVALUATION
                              ? color.green
                              : companyInfo?.status ===
                                CompanyStatus.NEEDS_MORE_INFO
                              ? color.yellow
                              : color.red,
                        }}
                      />
                    ) : null}
                    {(companyInfo?.status === CompanyStatus.ERROR ||
                      companyInfo?.status ===
                        CompanyStatus.TIME_LIMIT_EXCEEDED) && <ErrorIcon />}
                    {(companyInfo?.status ===
                      CompanyStatus.EVALUATION_IN_PROGRESS ||
                      companyInfo?.status ===
                        CompanyStatus.FETCHING_COMPANY_DETAILS ||
                      companyInfo?.status ===
                        CompanyStatus.PROCESSING_COMPANY_INFORMATIONS ||
                      companyInfo?.status ===
                        CompanyStatus.GRADING_COMPANY) && (
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                      </Box>
                    )}
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: disablingEdit
                    ? "rgba(0, 0, 0, 0.2)"
                    : "inherit",
                },
              }}
              InputLabelProps={{
                style: { color: "white" },
              }}
            />
          </Grid>
          {!evaluationInProgress && (
            <CompanyActions
              companyInfo={companyInfo}
              companyId={companyId}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
              onScrollToComponent={handleScrollToComponent}
              onOpenFileUploadModal={handleOpenFileUploadModal}
              disablingEdit={disablingEdit}
              visibility={visibility}
            />
          )}
        </Grid>
        <Grid item padding={"0 16px"}>
          <TextField
            value={companyInfo.industry || ""}
            label="Industry"
            disabled={disablingEdit}
            onChange={(e) => {
              setCompanyInfo({ ...companyInfo, industry: e.target.value });
              handleFieldChange();
            }}
            variant="outlined"
            fullWidth
            InputProps={{
              sx: {
                backgroundColor: disablingEdit
                  ? "rgba(0, 0, 0, 0.2)"
                  : "inherit",
              },
            }}
            InputLabelProps={{
              style: { color: "white" },
              shrink: true,
            }}
            sx={{ marginBottom: 2 }}
          />
        </Grid>
        <Grid item padding={"0 16px"}>
          <TextField
            value={companyInfo.location || ""}
            label="Location"
            disabled={disablingEdit}
            onChange={(e) => {
              setCompanyInfo({ ...companyInfo, location: e.target.value });
              handleFieldChange();
            }}
            variant="outlined"
            fullWidth
            InputProps={{
              sx: {
                backgroundColor: disablingEdit
                  ? "rgba(0, 0, 0, 0.2)"
                  : "inherit",
              },
            }}
            InputLabelProps={{
              style: { color: "white" },
              shrink: true,
            }}
          />
        </Grid>
        <Grid item padding={"0 16px"}>
          <TextField
            label="About"
            multiline
            fullWidth
            value={companyInfo.about}
            disabled={disablingEdit}
            onChange={(e) => {
              setCompanyInfo({ ...companyInfo, about: e.target.value });
              handleFieldChange();
            }}
            variant="outlined"
            margin="normal"
            InputProps={{
              sx: {
                color: disablingEdit ? "white" : "inherit",
                backgroundColor: disablingEdit
                  ? "rgba(0, 0, 0, 0.2)"
                  : "inherit",
              },
            }}
            InputLabelProps={{
              style: { color: "white" },
            }}
          />
        </Grid>

        <Grid item padding={"0 16px"} marginBottom={2} marginTop={1}>
          <FormControl fullWidth>
            <InputLabel
              id="evaluation-select-label"
              sx={{ color: color.white }}
            >
              Select Evaluation
            </InputLabel>
            <Select
              labelId="evaluation-select-label"
              id="evaluation-select"
              value={selectedEvaluationId || ""}
              label="Select Evaluation"
              onChange={handleEvaluationChange}
              IconComponent={() => null}
              sx={{
                color: color.white,
                backgroundColor: "transparent",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: color.white,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: color.white,
                },
                ".MuiSvgIcon-root": {
                  display: "none",
                },
                display: "flex",
                alignItems: "center",
                ".MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: color.primary_dark,
                    opacity: 0.95,
                  },
                },
              }}
            >
              {companyInfo.evaluations.length === 0 ? (
                <MenuItem value="start-evaluation">
                  <ListItemIcon>
                    <Assessment sx={{ color: color.btn_dark }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        style={{
                          color: theme.palette.primary.main,
                          opacity: 0.7,
                        }}
                      >
                        Start Evaluation
                      </Typography>
                    }
                  />
                </MenuItem>
              ) : (
                companyInfo.evaluations.map((evaluation) => (
                  <MenuItem
                    key={evaluation.evaluation_id}
                    value={evaluation.evaluation_id}
                    sx={{
                      color: "white",
                      backgroundColor: "transparent",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      },
                    }}
                  >
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body1" sx={{ color: "white" }}>
                        {`Evaluation : ${
                          evaluation.question_list_name
                        } in  ${Object.keys(languageMappings).find(
                          (key) => languageMappings[key] === evaluation.language
                        )}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: color.btn_dark }}
                      >
                        {`Grade: ${evaluation.grade}`}
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteEvaluation(evaluation.evaluation_id);
                      }}
                      sx={{ color: color.red }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid ref={evaluationRef} mb={5}>
              {selectedEvaluationId &&
                companyInfo.evaluations.length > 0 &&
                companyInfo.evaluations
                  .filter(
                    (evaluation) =>
                      evaluation.evaluation_id === selectedEvaluationId
                  )
                  .map((evaluation) => {
                    const evaluationData = handleEvaluationData(evaluation);
                    if (!evaluationData) return null;
                    return (
                      <Evaluation
                        key={evaluation.evaluation_id}
                        evaluationResponse={
                          evaluationData as EvaluationResponse
                        }
                        questionList={questionList}
                        answers={answers || {}}
                        handleAnswerChange={handleAnswerChangeWrapper}
                        handleFileChange={handleFileChange}
                        handleSubmitUpdateAboutCompany={
                          handleSubmitUpdateAboutCompany
                        }
                        resetAnswers={resetAnswers}
                        isQuestionFieldDisabled={disablingEdit}
                        onAnswerChange={handleFieldChange}
                      />
                    );
                  })}
            </Grid>
          </Grid>
        </Grid>

        {isFileUploadModalOpen && (
          <FileUploadModal
            open={isFileUploadModalOpen}
            onClose={handleCloseFileUploadModal}
            documents={companyInfo.documents}
          />
        )}
      </Container>
      {disablingEdit === false && (
        <Grid
          item
          xs={12}
          mt={5}
          sx={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
            position: "absolute",
            bottom: 0,
            backgroundColor: color.secondaryDark,
            padding: "1rem",
            zIndex: 1000,
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
            width: "100%",
          }}
        >
          <div>
            <Button
              variant="contained"
              disabled={!isSaveButtonEnabled}
              onClick={() => {
                handleSave(companyId);
              }}
              sx={{
                backgroundColor: color.btn_dark,
                color: "white",
                "&:hover": {
                  backgroundColor: color.primary_dark,
                  color: color.btn_dark,
                },
              }}
            >
              Save Company
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              onClick={handleCancel}
              sx={{
                backgroundColor: color.secondary,
                color: "white",
                "&:hover": {
                  backgroundColor: color.secondary,
                },
              }}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      )}
    </>
  );
};

export default SingleCompanyComponent;
