import React from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import colors from "../../Constants/colors";
import InfoButton from "./InfoButton";

interface KnowledgeBaseAndCurrentTrendsProps {
  formState: any;
  handleLinkChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    section: "knowledgebase" | "trends",
    index: number
  ) => void;
  handleAddLink: (section: "knowledgebase" | "trends") => void;
  handleRemoveLink: (
    section: "knowledgebase" | "trends",
    index: number
  ) => void;
}

const KnowledgeBaseAndCurrentTrends: React.FC<
  KnowledgeBaseAndCurrentTrendsProps
> = ({ formState, handleLinkChange, handleAddLink, handleRemoveLink }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20px",
        marginLeft: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "25px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            flexGrow: 1,
            color: colors.white,
            textAlign: "center",
            marginBottom: "0",
            marginRight: {
              xs: "0",
              md: "24px",
            },
          }}
        >
          Knowledge Base and Current Trends
        </Typography>
        <InfoButton info="Provide links to high-quality documents that form the foundation of your expert's knowledge base, as well as those highlighting current trends in the relevant industry." />
      </Box>
      <Grid container spacing={3} justifyContent="center">
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingLeft: "0 !important",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: colors.white,
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            Knowledgebase Links
          </Typography>
          {formState.knowledgebase.map((link: string, index: number) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                label={`Knowledgebase Link ${index + 1}`}
                value={link}
                onChange={(e) => handleLinkChange(e, "knowledgebase", index)}
                margin="normal"
                InputLabelProps={{ style: { color: colors.white } }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    color: colors.white,
                    "&:hover fieldset": {
                      borderColor: colors.white,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: colors.white,
                    },
                  },
                  "& label.Mui-focused": {
                    color: colors.white,
                  },
                }}
              />
              <IconButton
                onClick={() => handleRemoveLink("knowledgebase", index)}
                sx={{ marginLeft: "10px", color: colors.white }}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Box>
          ))}
          <Button
            onClick={() => handleAddLink("knowledgebase")}
            sx={{
              marginTop: "10px",
              backgroundColor: colors.btn_dark,
              color: colors.white,
              "&:hover": {
                backgroundColor: colors.primary_dark,
                color: colors.btn_dark,
              },
            }}
            startIcon={<AddCircleOutlineIcon />}
          >
            Add a Knowledge Base Link
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingLeft: "0 !important",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: colors.white,
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            Trends Links
          </Typography>
          {formState.trends.map((link: string, index: number) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                label={`Trends Link ${index + 1}`}
                value={link}
                onChange={(e) => handleLinkChange(e, "trends", index)}
                margin="normal"
                InputLabelProps={{ style: { color: colors.white } }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    color: colors.white,
                    "&:hover fieldset": {
                      borderColor: colors.white,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: colors.white,
                    },
                  },
                  "& label.Mui-focused": {
                    color: colors.white,
                  },
                }}
              />
              <IconButton
                onClick={() => handleRemoveLink("trends", index)}
                sx={{ marginLeft: "10px", color: colors.white }}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Box>
          ))}
          <Button
            onClick={() => handleAddLink("trends")}
            sx={{
              marginTop: "10px",
              marginLeft: "auto",
              marginRight: "auto",
              backgroundColor: colors.btn_dark,
              color: colors.white,
              "&:hover": {
                backgroundColor: colors.primary_dark,
                color: colors.btn_dark,
              },
            }}
            startIcon={<AddCircleOutlineIcon />}
          >
            Add Trends Link
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default KnowledgeBaseAndCurrentTrends;
