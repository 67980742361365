import React from "react";
import { Grid, Paper, Tooltip, Typography } from "@mui/material";
import {
  formatDate,
  getFileIcon,
  truncateString,
} from "../../../helpers/helpers";
import color from "../../../Constants/colors";

interface UploadedFilesPanelProps<T> {
  file: T;
  getFileName: (file: T) => string;
  getFileCreated: (file: T) => string | undefined;
  getFileStatus: (file: T) => number;
  getFileUpdated: (file: T) => string | undefined;
}

const TempUploadedFilesPanel = <T,>({
  file,
  getFileName,
  getFileStatus,
  getFileCreated,
  getFileUpdated,
}: UploadedFilesPanelProps<T>) => {
  const fileName = getFileName(file);
  // const fileStatus = getFileStatus(file);
  const fileCreated = formatDate(getFileCreated(file));
  const fileUpdated = formatDate(getFileUpdated(file));
  return (
    <Grid
      item
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mb: 2,
        maxWidth: 120,
      }}
      maxWidth={200}
    >
      <Paper
        variant="outlined"
        sx={{
          width: 80,
          height: 80,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          overflow: "hidden",
          padding: 1,
          "&:hover .remove-icon": {
            display: "flex",
          },
          "& .MuiSvgIcon-root": {
            height: "100%",
            width: "100%",
            maxHeight: 100,
            maxWidth: 100,
          },
        }}
      >
        <Tooltip
          title={
            <>
              <div>{`Created at: ${fileCreated}`}</div>
              <div>{`Updated at: ${fileUpdated}`}</div>
            </>
          }
        >
          {getFileIcon(fileName)}
        </Tooltip>
      </Paper>

      <Typography
        variant="caption"
        sx={{
          color: color.btn_dark,
          fontWeight: "bold",
          marginTop: "3px",
          textAlign: "center",
          maxWidth: 100,
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        title={fileName}
      >
        {truncateString(fileName, 18)}
      </Typography>
    </Grid>
  );
};

export default TempUploadedFilesPanel;
