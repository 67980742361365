import axios from "axios";
import { EvaluationResponse, MoreInfoResponse } from "../../Models/evaluation";
import { evaluationModelAccessResponse } from "../../Models/evaluationModel";

export const fetchEvaluate = async (
  company_id: number,
  model_id: number,
  chunkSize: number,
  chunkOverlap: number,
  questionListId: number,
  certificate_id: number,
  languageId: number,
  useAlanExpert?: boolean
): Promise<EvaluationResponse | MoreInfoResponse> => {
  try {
    const { data } = await axios.post<EvaluationResponse | MoreInfoResponse>(
      `${process.env.REACT_APP_BASE_URL}/v1/evaluation/`,
      {
        company_id: company_id,
        model_id: model_id,
        chunk_size: chunkSize,
        chunk_overlap: chunkOverlap,
        question_list_id: questionListId,
        certificate_id: certificate_id,
        language: languageId,
        use_alan_expert: useAlanExpert,
      },
      { withCredentials: true }
    );

    return data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.error || "An error occurred.");
    } else if (error.request && error.request.responseText) {
      try {
        const parsedError = JSON.parse(error.request.responseText);
        throw new Error(parsedError.error || "An error occurred.");
      } catch (parseError) {
        throw new Error("An error occurred while parsing the response.");
      }
    } else {
      throw new Error("An unexpected error occurred.");
    }
  }
};

export const fetchModelOptions = async () => {
  const response = await axios.get<evaluationModelAccessResponse>(
    `${process.env.REACT_APP_BASE_URL}/v1/llm/models/`,
    { withCredentials: true }
  );
  return response.data;
};
