import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createKnowledgeBase,
  deleteKnowledgeBase,
  getKnowledgeBase,
  getSingleKnowledgeBase,
  updateSingleKnowledgeBase,
} from "../Services/Https/knowledge";
import {
  GetAllKnowledgeBasesResponse,
  GetSingleKnowledgeBaseResponse,
} from "../Models/knowledge";
import { enqueueSnackbar } from "notistack";
import { handleAxiosError } from "../Services/Https/errorHandler";
import { AxiosError } from "axios";

export const useKnowledge = () => {
  return useMutation(
    (newKnowledgeBase: { name: string; description: string; files: File[] }) =>
      createKnowledgeBase(
        newKnowledgeBase.name,
        newKnowledgeBase.description,
        newKnowledgeBase.files
      )
  );
};

export const useKnowledgeList = () => {
  return useQuery<GetAllKnowledgeBasesResponse[], Error>(
    ["knowledgeList"],
    () => getKnowledgeBase()
  );
};

export const useSingleKnowledge = (knowledgeBaseId: number) => {
  return useQuery<GetSingleKnowledgeBaseResponse, AxiosError>(
    ["singleKnowledgeBase", knowledgeBaseId],
    () => getSingleKnowledgeBase(knowledgeBaseId),
    { enabled: !!knowledgeBaseId }
  );
};

export const useUpdateKnowledge = () => {
  return useMutation(
    (params: {
      knowledgeBaseId: number | null;
      name: string;
      description: string;
    }) =>
      updateSingleKnowledgeBase(
        params.knowledgeBaseId,
        params.name,
        params.description
      )
  );
};

export const useDeleteKnowledgeBase = () => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => deleteKnowledgeBase(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("knowledgeList");
      enqueueSnackbar("Successfully deleted the Knowledge Base", {
        variant: "success",
      });
    },
    onError: (error) => {
      const errorMessage = handleAxiosError(error);
      enqueueSnackbar(`Error deleting the knowledge base: ${errorMessage}`, {
        variant: "error",
      });
    },
  });
};
