import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/system";
import FieldCard from "./FieldCard";
import Footer from "./Footer";
import HeroSection from "./HeroSection";
import color from "../../Constants/colors";
import { Assessment, Assignment, Speed } from "@mui/icons-material";

const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  fontFamily: "'Work Sans', sans-serif",
}));

const ScrollSection = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(10),
  backgroundColor: color.primary,
  color: color.primary_dark,
  minHeight: "100vh",
  width: "100vw",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  margin: theme.spacing(5),
}));

const DecorativeLine = styled(Box)(({ theme }) => ({
  width: "100px",
  height: "4px",
  background: color.accent,
  margin: "0 auto",
  marginBottom: theme.spacing(2),
}));

const SmallGrayText = styled(Typography)(({ theme }) => ({
  color: color.primary_medium,
  fontSize: "16px",
  textAlign: "center",
  marginBottom: theme.spacing(4),
  padding: "0 2rem",
}));

const HomePage: React.FC = () => {
  return (
    <Root>
      <HeroSection />
      <ScrollSection>
        <DecorativeLine />
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          style={{
            fontFamily: "'Barlow', sans-serif",
            textAlign: "center",
            marginBottom: "1rem",
          }}
        >
          Explore Our Services
        </Typography>
        <SmallGrayText>
          Discover how our services can help you transform and grow your
          business.
        </SmallGrayText>
        <ContentSection sx={{ marginTop: "3rem" }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <FieldCard
                title="Evaluation"
                description="Evaluate your company on specific standards by processing documents and categorizing them. Ensure compliance and improve your processes effectively."
                hoverText="Get detailed insights and ratings based on your company's documentation."
                icon={<Assessment fontSize="large" style={{ fontSize: 60 }} />}
                link="/evaluation"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FieldCard
                title="Expert Specification Form"
                description="Fill in information and make exports with ease using our form tools. Streamline data collection, improve accuracy, and facilitate reporting."
                hoverText="Optimize your processes with our comprehensive forms."
                icon={<Assignment fontSize="large" style={{ fontSize: 60 }} />}
                link="/expert-form"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FieldCard
                title="Digital Performance"
                description="Leverage AI to search the internet and receive useful information. Stay ahead of the competition with real-time data and actionable intelligence."
                hoverText="Improve your digital performance with AI-powered insights."
                icon={<Speed fontSize="large" style={{ fontSize: 60 }} />}
                link="/digital-performance"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            sx={{ marginTop: "2rem" }}
          >
            <Grid item xs={12} md={4}>
              <FieldCard
                title="Dussmann Knowledge Import"
                description="Upload your files for automated, secure processing. Key data is extracted to create Comma Soft Alans collections with complete confidentiality and no data retention"
                hoverText="Streamline data processing with our secure knowledge import tool"
                icon={
                  <img
                    src="/dussmann-logo.png"
                    alt="Dussmann Logo"
                    style={{ width: 60, height: 60 }}
                  />
                }
                link="/upload-csv"
              />
            </Grid>
          </Grid>
        </ContentSection>
      </ScrollSection>
      <Footer />
    </Root>
  );
};

export default HomePage;
