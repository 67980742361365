import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Input,
  TablePagination,
  TableSortLabel,
  Grid,
  useMediaQuery,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IosShareIcon from "@mui/icons-material/IosShare";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import color from "../../Constants/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import HardwareRoundedIcon from '@mui/icons-material/HardwareRounded';
import HardwareOutlinedIcon from '@mui/icons-material/HardwareOutlined';
import { QuestionItem } from "../../Models/question";
import { useSnackbar } from "notistack";
import {
  setDefaultQuestionList,
  copyQuestionList,
  deleteQuestionList,
  fetchQuestionLists,
  exportQuestionList,
} from "../../Services/Https/questions";
import { useToggleArchiveStatus } from "../../Hooks/useQuestions";
import theme from "../../Constants/theme";
import { UserRole } from "../../Models/user";
import { handleAxiosError } from "../../Services/Https/errorHandler";
import { downloadJSON } from "../../helpers/helpers";
import { useSortableData } from "../../Hooks/useSortableData";

interface MyQuestionsProps {
  userRole: UserRole;
}

const MyQuestions: React.FC<MyQuestionsProps> = ({ userRole }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState<QuestionItem[]>([]);
  const [questionListCount, setQuestionListCount] = useState(0);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedList, setSelectedList] = useState<QuestionItem | null>(null);
  const toggleArchiveMutation = useToggleArchiveStatus();

  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { sortedItems, sortField, sortOrder, handleSort } =
    useSortableData<QuestionItem>(data, "name");

  useEffect(() => {
    const loadData = async () => {
      const response = await fetchQuestionLists(page, rowsPerPage, searchTerm);
      setData(response.question_lists);
      setQuestionListCount(response.total);
    };
    loadData().catch();
  }, [page, rowsPerPage, searchTerm]);

  const handleExportAdminList = async (id: number, name: string) => {
    try {
      const response = await exportQuestionList(id);
      downloadJSON(response, `${name}.json`);
      enqueueSnackbar(`Question list exported successfully.`, {
        variant: "success",
      });
    } catch (error) {
      const errorMessage = handleAxiosError(error);
      enqueueSnackbar(`Error exporting question list: ${errorMessage}`, {
        variant: "error",
      });
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteQuestionList(id);
      const newData = data.filter((item) => item.id !== id);
      setData(newData);
      setQuestionListCount((prevCount) => prevCount - 1);
      enqueueSnackbar("Question list deleted successfully.", {
        variant: "success",
      });

      if (newData.length === 0 && page > 1) {
        setPage(page - 1);
      } else {
        const loadData = async () => {
          const response = await fetchQuestionLists(
            page,
            rowsPerPage,
            searchTerm
          );
          setData(response.question_lists);
        };
        loadData().catch((error) => {
          enqueueSnackbar("Error loading question lists.", {
            variant: "error",
          });
        });
      }
    } catch (error) {
      enqueueSnackbar("An error occurred while deleting the question list.", {
        variant: "error",
      });
    }
  };

  const handleEdit = (item: any) => {
    navigate(`/cert_category`, {
      state: {
        item,
        action: "edit",
      },
    });
  };

  const handleCopy = async (item: any) => {
    // first get list details
    try {
      const response = await copyQuestionList(item.id);

      const responseQuestionLists = await fetchQuestionLists(
        page,
        rowsPerPage,
        searchTerm
      );

      const newQuestionItem = responseQuestionLists.question_lists.find(
        (questionList) => questionList.id === response.question_list_id
      );

      enqueueSnackbar("Question list copied successfully.", {
        variant: "success",
      });

      navigate(`/cert_category`, {
        state: {
          item: newQuestionItem,
          action: "copy",
        },
      });
    } catch (error) {
      enqueueSnackbar("An error occurred while copying the question list.", {
        variant: "error",
      });
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (searchTerm !== "") {
      setPage(1);
    }
  }, [searchTerm]);

  const handleConfirmationDialogOpen = (id: number) => {
    setCurrentId(id);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogClose = async (confirmed: boolean) => {
    if (confirmed && currentId !== null) {
      await handleDelete(currentId);
    }
    setConfirmationDialogOpen(false);
    setCurrentId(null);
  };

  const handleToggleArchive = async (id: number, archived: boolean) => {
    try {
      await toggleArchiveMutation.mutateAsync(id);
      setData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, archived: !item.archived } : item
        )
      );
      enqueueSnackbar(
        `Item ${archived ? "unarchived" : "archived"} successfully`,
        { variant: "success" }
      );
    } catch (error) {
      enqueueSnackbar("Error toggling archive status", { variant: "error" });
    }
  };

  const handleClickOpen = (questionList: QuestionItem) => {
    setSelectedList(questionList);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedList(null);
  };

  const loadData = useCallback(async () => {
    const response = await fetchQuestionLists(page, rowsPerPage, searchTerm);
    setData(response.question_lists);
    setQuestionListCount(response.total);
  }, [page, rowsPerPage, searchTerm]);

  const handleConfirmDefault = async () => {
    if (selectedList) {
      try {
        await setDefaultQuestionList(selectedList.id, selectedList.category.id);
        await loadData();
      } catch (error) {
        const errorMessage = handleAxiosError(error);
        enqueueSnackbar(`Error fetching question lists: ${errorMessage}`, {
          variant: "error",
        });
      }
      handleClose();
    }
  };

  useEffect(() => {
    loadData().catch();
  }, [loadData]);

  const getDefaultListTitle = (item: QuestionItem) => {
    if (item.default) {
      return "This is a Default list";
    } else if (item.archived) {
      return "Archived lists cannot be set to Default";
    } else {
      return "Make list Default";
    }
  };

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20px",
        width: "100%",
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        style={{ color: "white", marginBottom: "20px" }}
      >
        My Question Lists
      </Typography>
      <Input
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          width: "30%",
          marginBottom: "30px",
          color: "white",
          textAlign: "center",
        }}
        className="custom-input"
      />
      <Button
        onClick={() => navigate(`/cert_category`)}
        sx={{
          marginBottom: "20px",
          backgroundColor: color.btn_dark,
          color: "white",
          padding: "10px 20px",
          borderRadius: "5px",
          border: "none",
          cursor: "pointer",
          width: "fit-content",
          "&:hover": {
            backgroundColor: color.primary_dark,
            color: color.btn_dark,
          },
        }}
      >
        Create New Question List
      </Button>
      <TableContainer
        sx={{
          width: matches ? "100vw" : "80%",
          overflowX: "auto",
          padding: matches ? "0 1rem" : "0",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => handleSort("name")}
              >
                Name
                {sortField === "name" && (
                  <TableSortLabel active direction={sortOrder} />
                )}
              </TableCell>
              <TableCell
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => handleSort("category")}
              >
                Evaluation Category
                {sortField === "category" && (
                  <TableSortLabel active direction={sortOrder} />
                )}
              </TableCell>
              <TableCell style={{ color: "white" }}>Actions</TableCell>
              <TableCell style={{ color: "white", minWidth: "100px" }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedItems.map((item) => (
              <TableRow
                key={item.id}
                sx={{
                  backgroundColor: item.default
                    ? "rgba(242, 141, 1, 0.12)"
                    : "transparent",
                  "&:hover": { backgroundColor: color.primary_dark },
                }}
              >
                <TableCell
                  style={{
                    color: "white",
                    fontWeight: item.default ? "bold" : "normal",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/question_list/${item.id}`)}
                >
                  <Tooltip
                    title={item.default ? "This is the default list" : ""}
                  >
                    <span>{item.name}</span>
                  </Tooltip>
                </TableCell>
                <TableCell
                  style={{
                    color: "white",
                    fontWeight: item.default ? "bold" : "normal",
                  }}
                >
                  <Tooltip
                    title={item.default ? "This is the default list" : ""}
                  >
                    <span>{item.category.name}</span>
                  </Tooltip>
                </TableCell>
                <TableCell style={{ color: "white" }}>
                  {userRole !== UserRole.EVALUATOR && item.is_edit && (
                    <Tooltip
                      title={
                        item.default
                          ? "You can't edit a default list"
                          : item.in_use
                          ? "This item is currently in use and cannot be edited"
                          : "Edit"
                      }
                    >
                      <span>
                        <IconButton
                          style={{
                            color: color.btn_dark,
                            opacity: item.in_use || item.default ? 0.4 : 1,
                          }}
                          onClick={() =>
                            !item.in_use && !item.default && handleEdit(item)
                          }
                          disabled={item.in_use || item.default}
                        >
                          <EditIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                  {userRole !== UserRole.EVALUATOR && (
                    <Tooltip
                      title={
                        item.default
                          ? "You can't delete a default list"
                          : item.in_use
                          ? "This item is currently in use and cannot be deleted"
                          : "Delete"
                      }
                    >
                      <span>
                        <IconButton
                          onClick={() => {
                            if (!item.in_use && !item.default) {
                              handleConfirmationDialogOpen(item.id);
                            }
                          }}
                          disabled={item.default || item.in_use}
                          style={{
                            color: "red",
                            opacity: item.default || item.in_use ? 0.4 : 1,
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                  <Tooltip title="Copy">
                    <IconButton
                      style={{ color: color.btn_dark }}
                      onClick={() => handleCopy(item)}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                  {userRole !== UserRole.EVALUATOR && (
                    <Tooltip
                      title={
                        item.default
                          ? "Default lists cannot be archived"
                          : item.archived
                          ? "Unarchive"
                          : "Archive"
                      }
                    >
                      <span>
                        <IconButton
                          onClick={() =>
                            handleToggleArchive(item.id, item.archived)
                          }
                          disabled={item.default}
                          style={{
                            color: color.btn_dark,
                            opacity: item.default ? 0.4 : 1,
                          }}
                        >
                          {item.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                  {userRole === UserRole.ADMIN && (
                    <>
                      <Tooltip title={getDefaultListTitle(item)}>
                        <span>
                          <IconButton
                            onClick={() => handleClickOpen(item)}
                            color="primary"
                            style={{
                              color: color.btn_dark,
                              pointerEvents:
                                item.default || item.archived ? "none" : "auto",
                              opacity: item.default || item.archived ? 0.6 : 1,
                            }}
                            disabled={item.default || item.archived}
                          >
                            {item.default ? (
                              <HardwareRoundedIcon />
                            ) : (
                              <HardwareOutlinedIcon />
                            )}
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Export question list as JSON">
                        <span>
                          <IconButton
                            onClick={() =>
                              handleExportAdminList(item.id, item.name)
                            }
                            color="primary"
                            style={{
                              color: color.btn_dark,
                            }}
                          >
                            <IosShareIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </>
                  )}
                  {userRole === UserRole.EVALUATOR && !item.default && (
                    <>
                      <Tooltip
                        title={
                          item.in_use
                            ? "This item is currently in use and cannot be edited"
                            : "Edit"
                        }
                      >
                        <span>
                          <IconButton
                            style={{
                              color: color.btn_dark,
                              opacity: item.in_use ? 0.4 : 1,
                            }}
                            onClick={() => !item.in_use && handleEdit(item)}
                            disabled={item.in_use}
                          >
                            <EditIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip
                        title={
                          item.in_use
                            ? "This item is currently in use and cannot be deleted"
                            : "Delete"
                        }
                      >
                        <span>
                          <IconButton
                            onClick={() => {
                              if (!item.in_use) {
                                handleConfirmationDialogOpen(item.id);
                              }
                            }}
                            disabled={item.in_use}
                            style={{
                              color: "red",
                              opacity: item.in_use ? 0.4 : 1,
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title={item.archived ? "Unarchive" : "Archive"}>
                        <span>
                          <IconButton
                            onClick={() =>
                              handleToggleArchive(item.id, item.archived)
                            }
                            style={{
                              color: color.btn_dark,
                            }}
                          >
                            {item.archived ? (
                              <UnarchiveIcon />
                            ) : (
                              <ArchiveIcon />
                            )}
                          </IconButton>
                        </span>
                      </Tooltip>
                    </>
                  )}
                </TableCell>
                <TableCell style={{ color: "white" }}>
                  {item.archived ? "Archived" : "Active"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Grid item>
          <Dialog
            open={confirmationDialogOpen}
            onClose={() => handleConfirmationDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">
              <Typography sx={{ color: color.white }}>
                Delete question list confirmation
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography variant="caption" sx={{ color: color.white }}>
                  Are you sure you want to delete list?
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{
                  backgroundColor: color.btn_dark,
                  color: color.white,
                  marginBottom: "10px",
                  marginTop: "10px",
                  "&:hover": {
                    backgroundColor: color.primary_dark,
                    color: color.btn_dark,
                  },
                }}
                variant="outlined"
                onClick={() => {
                  handleConfirmationDialogClose(false);
                }}
              >
                No
              </Button>
              <Button
                sx={{
                  backgroundColor: color.btn_dark,
                  color: color.white,
                  marginBottom: "10px",
                  marginTop: "10px",
                  "&:hover": {
                    backgroundColor: color.primary_dark,
                    color: color.btn_dark,
                  },
                }}
                variant="outlined"
                onClick={() => {
                  handleConfirmationDialogClose(true);
                }}
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={questionListCount}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ color: color.white }}>Confirm Default</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: color.white }}>
            Are you sure you want to make the question list "
            {selectedList?.name}" default for the certification category "
            {selectedList?.category.name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: color.btn_dark,
              color: color.white,
              marginBottom: "10px",
              marginTop: "10px",
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: color.btn_dark,
              color: color.white,
              marginBottom: "10px",
              marginTop: "10px",
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
            }}
            onClick={handleConfirmDefault}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default MyQuestions;
