import React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SvgIconComponent } from "@mui/icons-material";

type MenuItemType = {
  label: string;
  icon?: SvgIconComponent;
  action?: () => void;
  submenu?: MenuItemType[];
  disabled?: boolean;
};

interface NestedMenuCustomProps {
  menuItems: MenuItemType[];
  name: string;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 20,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const NestedMenuCustom: React.FC<NestedMenuCustomProps> = ({
  menuItems,
  name,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [, setSubmenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [, setSubmenuOpenIndex] = React.useState<number | null>(null);
  const [activeMenuItems, setActiveMenuItems] =
    React.useState<MenuItemType[]>(menuItems);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubmenuAnchorEl(null);
    setSubmenuOpenIndex(null);
    setActiveMenuItems(menuItems);
  };

  const handleMenuItemClick = (action?: () => void) => {
    if (action) {
      action();
    }
    handleClose();
  };

  const handleSubmenuClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    items: MenuItemType[]
  ) => {
    event.stopPropagation();
    setSubmenuAnchorEl(event.currentTarget);
    setSubmenuOpenIndex(index);
    setActiveMenuItems(items);
  };

  const renderMenuItems = (items: MenuItemType[]) =>
    items.map((item, index) => (
      <div key={index}>
        <MenuItem
          onClick={(event) =>
            item.submenu
              ? handleSubmenuClick(event, index, item.submenu)
              : handleMenuItemClick(item.action)
          }
          disableRipple
          disabled={item.disabled}
        >
          {item.icon && <item.icon />}
          {item.label}
          {item.submenu && <ChevronRightIcon />}
        </MenuItem>
      </div>
    ));

  return (
    <div>
      <Button
        id="customized-button"
        aria-controls={open ? "customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          textTransform: "none",
          fontFamily: "inherit",
        }}
      >
        {name}
      </Button>
      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          "aria-labelledby": "customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {renderMenuItems(activeMenuItems)}
      </StyledMenu>
    </div>
  );
};

export default NestedMenuCustom;
