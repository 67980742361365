import axios from "axios";
import { EvaluationResponse } from "../../Models/evaluation";

export const getEvaluation = async (
  evaluationId: string
): Promise<EvaluationResponse> => {
  const { data } = await axios.get<EvaluationResponse>(
    `${process.env.REACT_APP_BASE_URL}/v1/evaluation/${evaluationId}/`,
    { withCredentials: true }
  );
  return data;
};

export const deleteEvaluation = async (evaluationId: string): Promise<void> => {
  await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/v1/evaluation/${evaluationId}/`,
    { withCredentials: true }
  );
};
